import React, { useEffect } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";

const NewsandAnnouncements = () => {
  useEffect(() => {
    document.title = "News and Announcements - Think Quotient";
    AOS.init();

  }, []);
  return (
    <div>

     

      {/* Header Section */}
      <Header />

      {/* Banner Section */}
      <div>
        <div className="news-banner-sec flex items-center">
          <div className="container text-left text-white mx-auto">
            <div className="md:w-1/2 mx-4 md:mx-0"
              data-aos="fade-up"
              data-aos-duration="1000">
              <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                News & Announcements
              </h1>
              <p className="text-lg mb-8">
                ThinkQuotient: Developer Program Session & Technical Seminar
              </p>
              <Link to="/contact?scrollTo=contact-form">
                <button className="btn-style-2 px-6 py-2 ">
                  Get In Touch With Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
        <h1 className="text-lg font-normal container mx-auto text-left"
          data-aos="fade-right"
          data-aos-duration="1000">
          <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          ullamcorper risus at gravida vestibulum. Vivamus fringilla justo vel
          augue ultrices, nec congue libero dapibus. Aliquam erat volutpat.
          Nullam aliquet, tortor ut sollicitudin finibus, orci elit aliquam
          libero, vitae euismod mi quam non dolor. Curabitur et metus sit amet
          nunc dapibus eleifend vitae non enim. Morbi eget vestibulum quam. In
          hac habitasse platea dictumst. Phasellus nec aliquam nulla. Quisque
          auctor aliquet ex, eget efficitur lacus blandit nec. Duis vulputate
          cursus nunc, id luctus ligula finibus nec. Sed sodales dui et
          vestibulum cursus. Integer ac dolor a nulla vestibulum ullamcorper
          vitae vitae nisl. Curabitur ac lacinia elit. Nunc vehicula, quam vel
          hendrerit ullamcorper, justo libero bibendum mauris, nec dictum felis
          odio ut quam.
        </h1>
      </div>

      <div className="container mx-auto py-14">
        <div className="wrapper-box-na"
          data-aos="fade-up"
          data-aos-duration="1000">
          <div className="box-na">
            <div className="inner-na">
              <p className="text-black text-base p-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                ullamcorper risus at gravida vestibulum. Vivamus fringilla justo
                vel augue ultrices, nec congue libero dapibus. Aliquam erat
                volutpat.
              </p>
            </div>
          </div>
          <div className="box-na">
            <div className="inner-na">
              <p className="text-black text-base p-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                ullamcorper risus at gravida vestibulum. Vivamus fringilla justo
                vel augue ultrices, nec congue libero dapibus. Aliquam erat
                volutpat.
              </p>{" "}
            </div>
          </div>
          <div className="box-na">
            <div className="inner-na">
              <p className="text-black text-base p-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                ullamcorper risus at gravida vestibulum. Vivamus fringilla justo
                vel augue ultrices, nec congue libero dapibus. Aliquam erat
                volutpat.
              </p>{" "}
            </div>
          </div>
          <div className="box-na">
            <div className="inner-na">
              <p className="text-black text-base p-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                ullamcorper risus at gravida vestibulum. Vivamus fringilla justo
                vel augue ultrices, nec congue libero dapibus. Aliquam erat
                volutpat.
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <TestimonialsandBlogSec />

      <WhyUs />
      <ClientLogoCarousel />
      <Footer />
    </div>
  );
};

export default NewsandAnnouncements;
