import React, { useEffect, useRef, useState } from "react";

import Footer from "../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SuccessStoryCarousel from "../Components/Home/SuccessStoryCarousel";
import StickyElements from "../Pages/Training/StickyElements";
import StudentTestimonials from "../Pages/Training/StudentTestimonials";
import Clientele from "../Pages/Training/Clientele";
import { useLocation } from 'react-router-dom';
import { useTable, useSortBy } from "react-table";


const WorkshopsPage = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);



  const location = useLocation();

  // Extract the query parameter value
  const searchParams = new URLSearchParams(location.search);
  const flag = searchParams.get('flag');
  useEffect(() => {
    document.title = "Workshops - Think Quotient";
    AOS.init();
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split('?'); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get('scrollTo');

      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    // Rest of your useEffect logic
    console.log("------------->", flag);
    console.log(location.state);
  }, []);



  const pageTitle = "Workshops - ThinkQuotient";
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();



  useEffect(() => {

    AOS.init();

  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");
      console.log("hello " + scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  const [tabIndex1, setTabIndex1] = React.useState(0);
  const [tabIndex2, setTabIndex2] = React.useState(0);
  const [tabIndex3, setTabIndex3] = React.useState(0);
  const [tabIndex4, setTabIndex4] = React.useState(0);
  const [tabIndex5, setTabIndex5] = React.useState(0);

  const data1 = [
    {
      count: "01",
      label: "Trusted brand in fresher's and corporate training, 10th year since inception",
      value: "tab1",
      // image: "/assets/images/Experienced Trainer.jpg",
      // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },

    {
      count: "02",
      label: "2000+ Freshers successfully placed over 450+ organizations",
      value: "tab2",
      // image: "/assets/images/programming-background-collage.jpg",
      // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
    },
    {
      count: "03",
      label: "Proven expertise in delivering industry-relevant skills.",
      // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab3",
      // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
    {
      count: "04",
      label: "Strong alumni and corporate network ",
      // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
      value: "tab4",
      // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
    },
    {
      count: "05",
      label: "Hands-on training designed for practical application",
      value: "tab5",
      // image: "/assets/images/Capstone Project.jpg",
      // desc: `Foster practical learning through hands-on projects that replicate real-world scenarios. Employees apply their skills to solve problems, building confidence and competence.`,
    },
    {
      count: "06",
      label: "Flexible schedule tailored for working professionals and students",
      // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab6",
      // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
    {
      count: "07",
      label: "Focused guidance on high-demand technologies and frameworks ",
      // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
      value: "tab7",
      // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
    },
    {
      count: "08",
      label: "Exposure to Live Projects",
      value: "tab8",
      // image: "/assets/images/Capstone Project.jpg",
      // desc: `Foster practical learning through hands-on projects that replicate real-world scenarios. Employees apply their skills to solve problems, building confidence and competence.`,
    },

  ];

  const data2 = [
    {
      count: "01",
      label: "Students who wants to gain knowledge on particular skill",
      value: "tab1",
      // image: "/assets/images/Experienced Trainer.jpg",
      // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },

    {
      count: "02",
      label: "Freshers who wants to excel in interviews ",
      value: "tab2",
      // image: "/assets/images/programming-background-collage.jpg",
      // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
    },
    {
      count: "03",
      label: "Developers eager to strengthen their technical foundations",
      // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab3",
      // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
    {
      count: "04",
      label: "Freshers who are willing to start their career",
      // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
      value: "tab4",
      // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
    },

  ];

  const data3 = [
    {
      count: "01",
      label: "These sessions will help to bridge the Knowledge Gap between candidate and expectation of Industry.",
      value: "tab1",
    },
    {
      count: "02",
      label: "These are live interactive training sessions where we discuss top interview questions of 2025",
      value: "tab2",
    },
    {
      count: "03",
      label: "These sessions will help you clear your doubts and will be help you crack the interviews with confidence",
      value: "tab3",
    },
    {
      count: "04",
      label: "Online interactive sessions - Learn from anywhere",
      value: "tab4",
      // image: "/assets/images/Experienced Trainer.jpg",
      // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },


  ];

  const data4 = [
    {
      count: "01",
      label: "Interview Preparation Sessions",
      value: "tab1",
    },
    {
      count: "02",
      label: "Focus on core concepts",
      value: "tab2",
    },
    {
      count: "03",
      label: "Frequently asked questions of each technology will be discussed",
      value: "tab3",
    },
    {
      count: "04",
      label: "Real life examples",
      value: "tab4",
    },
    {
      count: "05",
      label: "Industry-standard best practices",
      value: "tab5",
    },
    {
      count: "06",
      label: "Learn from industry experts with years of experience",
      value: "tab6",
    },
    {
      count: "07",
      label: "Interactive learning approach",
      value: "tab7",
    },
    {
      count: "08",
      label: " Low risk high reward",
      value: "tab8",
    },
  ];

  const data5 = [
    {
      count: "01",
      label: "Click on Register for Workshop button & Fill the Registration form",
      value: "tab1",
    },
    {
      count: "02",
      label: "Select options of your choice",
      value: "tab2",
    },
    {
      count: "03",
      label: "Pay registration amount Rs.999/-",
      value: "tab3",
    },

  ];


  const tabRef = useRef(null); // Create a ref for the tab content


  const handleTabClick1 = (index) => {
    setTabIndex1(index);

  };

  const handleTabClick2 = (index) => {
    setTabIndex2(index);

  };
  const handleTabClick3 = (index) => {
    setTabIndex3(index);

  };

  const handleTabClick4 = (index) => {
    setTabIndex4(index);

  };

  const handleTabClick5 = (index) => {
    setTabIndex5(index);

  };

  const tabledata = React.useMemo(
    () => [
      { srNo: 1, code: 'INT-101', topic: 'Technical Interview revision sessions', subtopic: 'Core Java', description: 'This program will cover revision of Core Java which comprises of questions from 1200 students who got job in Core Java across 350 organizations. Year 2025 top interview questions will be covered in this program. Emphasis will be on Collections, Threads, Lambda expressions etc.' },
      { srNo: 2, code: 'INT-102', topic: 'Technical Interview revision sessions', subtopic: 'Advanced Java, Spring, Data, MVC, Boot', description: 'This program will cover Advance Java concepts like Spring Core architecture, Core concepts of Spring like Dependency Injections, IoC, Data, Boot AutoConfiguration, Annotations etc Year 2025 top interview questions will be covered in this program.' },
      { srNo: 3, code: 'INT-103', topic: 'Technical Interview revision sessions', subtopic: 'C#', description: 'This program covers fundamentals of C#. CLR, OOPs, Delegates, Collections, and Generics.' },
      { srNo: 4, code: 'INT-104', topic: 'Technical Interview revision sessions', subtopic: 'Dot Net - MVC , Core', description: 'Preparation of interview on Dotnet, MVC. Discuss the latest questions & their answers asked in interview.' },
      { srNo: 5, code: 'INT-105', topic: 'Technical Interview revision sessions', subtopic: 'SQL , PL/SQL', description: 'This Program covers SQL fundamentals, Normalization, DDL, DML, Subqueries, Joins, Procedures, Functions triggers.' },
      { srNo: 6, code: 'INT-106', topic: 'Technical Interview revision sessions', subtopic: 'Python', description: 'Preparation of Python interview questions & their answers. Which questions asked in recent interviews, How to answer the questions.' },
      { srNo: 7, code: 'INT-107', topic: 'Technical Interview revision sessions', subtopic: 'Angular', description: 'Preparation of Angular interview questions & their answers. We will discuss the latest interview questions asked in interview & also demonstrate how to crack that answer.' },
      { srNo: 8, code: 'INT-108', topic: 'Technical Interview revision sessions', subtopic: 'QA - Manual + Automation', description: 'This Program covers Manual Testing, Test cases, Test scenarios, Agile methodology, Java Basic, Selenium, Locators, Waits.' },
      { srNo: 9, code: 'INT-109', topic: 'Softskill - interview revision sessions', subtopic: 'HR interview Questions, Group Discussion, Resume Guidelines, Project Guidance', description: 'Do\'s and Don\'ts of HR Interviews, Group Discussion guidance, Situation based questions, How to build good resume, Project related questions, STAR pattern.' },
      { srNo: 10, code: 'J-101', topic: 'Java', subtopic: 'OOPs', description: 'This Program revises OOPs pillars Polymorphism, Abstraction, Inheritance and Encapsulation in details with real life examples.' },
      { srNo: 11, code: 'J-102', topic: 'Java', subtopic: 'String , Array', description: 'This program covers String class overview along with important methods. Array internal working. Examples with logical problem statements.' },
      { srNo: 12, code: 'J-103', topic: 'Java', subtopic: 'Collection', description: 'All important collections lists , set , map are covered in this program.' },
      { srNo: 13, code: 'J-104', topic: 'Java', subtopic: 'Exception Handling, inner classes , Wrapper classes', description: 'This program covers types of Exceptions, their differences, types of inner classes and related interview questions.' },
      { srNo: 14, code: 'J-105', topic: 'Java', subtopic: 'JDBC , Design Patterns', description: 'These sessions include database connectivity steps, types of statements, their differences, and related methods.' },
      { srNo: 15, code: 'J-106', topic: 'Java', subtopic: 'MultiThreading , File Handling', description: 'This program covers fundamentals of MultiThreading, its advantages, ways to create threads, priorities, and file handling techniques.' },
      { srNo: 16, code: 'J-107', topic: 'Java', subtopic: 'Java new features', description: 'Covers features from Java 8, 9, and 10 including Lambada expressions, default methods, functional interfaces, Streams, and more.' },
      { srNo: 17, code: 'SP-101', topic: 'Spring', subtopic: 'Spring Framework , Spring data  and ORM , Web MVC', description: 'This Session covers SpringFramework concepts including Dependency Injection, AOP, Spring Containers, and ORM.' },
      { srNo: 18, code: 'SP-102', topic: 'Spring', subtopic: 'Spring Boot using rest API', description: 'Concept of REST API, HTTP Methods, Data Formats, and Spring Boot concepts like RestController, and Data Access layer.' },
      { srNo: 19, code: 'SP-103', topic: 'Spring', subtopic: 'Capstone Project CRUD operations', description: 'Capstone project using Angular as frontend and Spring Boot as backend.' },
      { srNo: 20, code: 'C#-101', topic: 'C#', subtopic: 'OOPS', description: 'Covers OOPS principles - Abstraction, Encapsulation, Inheritance & Polymorphism in C#.' },
      { srNo: 21, code: 'C#-102', topic: 'C#', subtopic: 'String , Array, Exception Handling', description: 'String manipulation, Arrays, Exception handling concepts.' },
      { srNo: 22, code: 'C#-103', topic: 'C#', subtopic: 'Collections, CRUD using collections', description: 'Using Collections in real time and CRUD operations.' },
      { srNo: 23, code: 'C#-104', topic: 'C#', subtopic: 'File Serialization, Multithreading', description: 'Serialization and multithreading concepts in C#.' },
      { srNo: 24, code: 'C#-105', topic: 'C#', subtopic: 'ADO.NET', description: 'Data management and CRUD operations using ADO.NET.' },
      { srNo: 25, code: 'MVC-101', topic: 'ASP.NET Core using MVC', subtopic: 'Introduction to MVC', description: 'Roles of Model, View & Controller in MVC with hands-on practice.' },
      { srNo: 26, code: 'MVC-102', topic: 'ASP.NET Core using MVC', subtopic: 'Entity Framework, DI Pattern', description: 'Layered architecture and data management with EF.' },
      { srNo: 27, code: 'MVC-103', topic: 'ASP.NET Core using MVC', subtopic: 'Security in MVC', description: 'Authentication, Authorization, and security practices.' },
      { srNo: 28, code: 'MVC-104', topic: 'ASP.NET Core using MVC', subtopic: 'REST Web API', description: 'Creating and testing REST APIs using Postman.' },
      { srNo: 29, code: 'MVC-105', topic: 'ASP.NET Core using MVC', subtopic: 'REST Web API integration with Angular', description: 'Integrating REST APIs with Angular.' },
      { srNo: 30, code: 'SQ-101', topic: 'SQL, PL/SQL', subtopic: 'Queries, Joins, Procedures, Functions', description: 'Fundamental,Normalization, Queries, Joins, Procedure, Function,  Triggers ', duration: 4, cost: 999 },
      { srNo: 31, code: 'AN-101', topic: 'Angular ', subtopic: 'Architecture, Typescript, Components, Directives, Pipes', description: 'This program will cover Angular Key concepts of Angular, Hands on practice on creating & working with Components, directices & Pipes', duration: 4, cost: 999 },
      { srNo: 32, code: 'AN-102', topic: 'Angular ', subtopic: 'Forms, Angular Services and Dependency Injection, Routing and Navigation, Rest apis', description: 'Working with different types of forms in Angular, What is the need of service, How to configure routing in Angular, Hands on practice on creating forms & routing', duration: 4, cost: 999 },
      { srNo: 33, code: 'AN-103', topic: 'Angular ', subtopic: 'Capstone Project CRUD operations', description: 'This sesssion will be Hands on practice to Create reactive forms in agnular connect with REST api & manage data in Angular', duration: 400, cost: 30000 },
      { srNo: 34, code: 'PY-101', topic: 'Python', subtopic: 'Python ,OOPS ', description: 'This session will cover Introduction to Python, what is object oriented approach ? how to implement OOPs approach to real time projects', duration: 100, cost: 19000 },
      { srNo: 35, code: 'PY-102', topic: 'Python', subtopic: 'Data science basics with Numpy , Panda', description: 'How to work with large dataset using Numpy & Pandas lib of Pyhthon. How to handle multi dimensional data & data analysis', duration: 500, cost: 49999 },
      { srNo: 36, code: 'PY-103', topic: 'Python ', subtopic: 'Functions, Collections, Exception handling, String', description: 'What is need of function, how to create functions in Python need to collections, types of collections in python What is exception ? why it is important to handle in program hands on practice of string & built in functions', duration: 4, cost: 999 },
      { srNo: 37, code: 'PY-104', topic: 'Python ', subtopic: 'Matplotlib, Seaborn library', description: 'Visualizations of data using bar, line, pie, histogram & scattor plot', duration: 400, cost: 30000 },
      { srNo: 38, code: 'QA-101', topic: 'QA', subtopic: 'Manual Testing + Automation Testing', description: 'This Program covers Manual Testing, Test cases, Test scenarios, Agile methodology, Java Basic, Selenium , Locatiors , Waits etc. It also covers basic of core java as it is needed for selenium', duration: 100, cost: 19000 },

    ],
    []
  );

  const columns = React.useMemo(
    () => [
      { Header: 'Sr. No.', accessor: 'srNo' },
      { Header: 'Code', accessor: 'code' },
      { Header: 'Topic', accessor: 'topic' },
      { Header: 'Subtopic', accessor: 'subtopic' },
      { Header: 'Description', accessor: 'description' },
      // { 
      //   Header: () => (
      //     <span>
      //       Duration <br /> (in hrs)
      //     </span>
      //   ), 
      //   accessor: 'duration' 
      // },
      // { 
      //   Header: () => (
      //     <span>
      //       Cost<br /> (Rs.)
      //     </span>
      //   ), 
      //   accessor: 'cost' 
      // },

    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: tabledata,
    },
    useSortBy  // Enables sorting functionality
  );

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>{pageTitle}</title>
        <meta name="title" content={metaTitle} />

        {/* Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Social Media Sharing */}
        <meta property="og:title" content={metaTitle || "Workshops - ThinkQuotient"} />
        <meta
          property="og:description"
          content="Join ThinkQuotient's online interactive interview preparation sessions. We are here with a series of sessions to help you prepare and crack any technical interview as a fresher."
        />
        <meta
          property="og:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />
        <meta property="og:url" content="https://www.thinkquotient.com/training/workshops" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle || "Workshops - ThinkQuotient"} />
        <meta
          name="twitter:description"
          content="Join ThinkQuotient's online interactive interview preparation sessions. We are here with a series of sessions to help you prepare and crack any technical interview as a fresher."
        />
        <meta
          name="twitter:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />

        {/* Robots and Author */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Think Quotient" />

        {/* Facebook Pixel Code */}
        <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '473030589157148');
          fbq('track', 'PageView');
        `}</script>
        <noscript>{`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=473030589157148&ev=PageView&noscript=1"/>
        `}</noscript>

        {/* Google Tag Manager */}
        <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
        `}</script>

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
        `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
        `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
        `}</script>

        {/* Google Tag Manager noscript */}
        <noscript>{`
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      `}</noscript>
      </Helmet>



      <div className="overflow-hidden">


        <div className="flex flex-col items-center py-14   px-6 campain-banner-sec ">

          {/* Logo Section */}
          <a href="https://www.thinkquotient.com" rel="noopener noreferrer">
            <img
              src="/assets/images/tq-logo.png"
              alt="Logo"
              className="absolute top-4 left-4 h-16 w-auto mb-4 "
            />
          </a>
          <h1
            className="text-base sm:text-lg font-normal container mx-auto text-center color-[#ffffff] "
          >
            <div >
              <p className="font-bold text-base sm:text-2xl xl:text-3xl  mt-12 lg:mt-24 md:ml-72 xl:ml-96">
                Accelerate Your Career with ThinkQuotient's Interview Preparation Sessions{" "}
              </p>
            </div>

            <p className="text-sm sm:text-lg xl:text-xl  mt-6 md:ml-72 xl:ml-96">After successfully placing 2000+ fresher candidates across 450+ organizations, we are here with series of sessions which will help you to prepare and crack any technical interview as a fresher.
            </p>
          </h1>
        </div>

        {/* Key Highlights Section */}
        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-xl sm:text-3xl xl:text-3xl font-bold ">
                Program Highlights  {" "}
              </p>
            </div>
          </h1>
        </div>

        <div className="flex flex-col lg:flex-row mt-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="w-full lg:w-[60%] container  mx-auto py-14 px-4 hidden  lg:block"
            ref={tabRef} >
            <Tabs
              selectedIndex={tabIndex1}
              onSelect={(index) => setTabIndex1(index)}
              className="block md:flex"
            >
              <TabList
                className="rounded-none bg-transparent p-0 tablist-div w-full"
                indicatorProps={{
                  className: "bg-transparent shadow-none rounded-none",
                }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                {data4.map(({ label }, index) => (
                  <Tab
                    key={index}
                    onClick={() => setTabIndex4(index)} // Update tabIndex on click
                    className={`${tabIndex4 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-800 py-2 px-4"
                      } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                    style={{
                      cursor: "pointer",
                      color: tabIndex4 === index ? "blue" : "inherit", // Inline style for color
                    }}
                  >
                    <a
                      href="#slide-sec"
                      onClick={(event) => event.preventDefault()}
                    >
                      <p
                        className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-2 ${tabIndex4 === index ? "text-blue-500" : "text-gray-800"
                          }`}
                      >
                        <span
                          className="bullet-point"
                          style={{
                            width: "3px", // Thin vertical line
                            height: "30px", // Length of the vertical line
                            backgroundColor: "#01a8ef", // custom color for the line
                            display: "inline-block",
                            marginRight: "1rem",
                            borderRadius: "0", // Keep it a straight line with no rounded corners
                          }}
                        ></span>
                        {label}
                      </p>
                    </a>
                  </Tab>
                ))}
              </TabList>

              {/* <div className="tab-content md:w-1/2 p-4" id="slide-sec">
                     {data[tabIndex] && (
                       <motion.div
                         key={tabIndex} // Re-run animation on tabIndex change
                         initial="initial"
                         animate="animate"
                         exit="exit"
                         variants={fadeVariants}
                         className="relative w-full"
                       >
                         <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                           <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                             <motion.img
                               src={data[tabIndex].image}
                               alt={`${data[tabIndex].label} image`}
                               className="object-cover w-full h-full rounded-lg"
                               initial={{ opacity: 0 }}
                               animate={{ opacity: 1, transition: { duration: 0.5 } }}
                             />
                           </div>
       
                           <motion.div
                             // className="bg-white shadow-lg p-8 rounded-lg w-full md:w-[calc(100%--3rem)] absolute top-[7rem] left-[12rem] md:h-auto overflow-auto"
                             className="custom-card"
                             initial={{ opacity: 0 }}
                             animate={{ opacity: 1, transition: { duration: 0.5 } }}
                           >
                             <p className="text-md md:text-lg">
                               {data[tabIndex].desc}
                             </p>
                           </motion.div>
                         </div>
                       </motion.div>
                     )}
                   </div>*/}
            </Tabs>
          </div>



          <div
            ref={tabRef}
            className="container mx-auto py-10 px-4 md:block lg:hidden">
            <Tabs
              selectedIndex={tabIndex4}
              onSelect={(index) => setTabIndex4(index)}
              className="block md:flex"
            >
              <TabList
                className="rounded-none bg-transparent p-0 tablist-div w-full"
                indicatorProps={{
                  className: "bg-transparent shadow-none rounded-none",
                }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                {data4.map(({ label }, index) => (
                  <Tab
                    key={index}
                    onClick={() => handleTabClick4(index)}// Update tabIndex on click
                    className={`${tabIndex4 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-800 py-2 px-4"
                      }`}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="#slide-sec"
                      onClick={(event) => event.preventDefault()}
                    >
                      <p
                        className={`md:ps-14 ps-4 font-semibold md:w-full text-base md:text-xl mb-0 md:mb-2  ${tabIndex4 === index ? "text-blue-500" : "text-gray-800"
                          }`}
                      >
                        <span
                          className="bullet-point"
                          style={{
                            width: "3px", // Thin vertical line
                            height: "30px", // Length of the vertical line
                            backgroundColor: "#01a8ef", // custom color for the line
                            display: "inline-block",
                            marginRight: "1rem",
                            borderRadius: "0", // Keep it a straight line with no rounded corners
                          }}
                        ></span>
                        {label}
                      </p>
                    </a>
                  </Tab>
                ))}
              </TabList>

              {/* <div ref={scrollRef} className="tab-content md:w-1/2 p-4" id="slide-sec" style={{ scrollMarginTop: "8.5rem" }}>
                     {data[tabIndex] && (
                       <div className="relative w-full">
                       
                         <div className="flex flex-col md:flex-col items-center md:items-start">
                           
                           <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                             <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                               <img
                                 src={data[tabIndex].image}
                                 alt={`${data[tabIndex].label} image`}
                                 className="object-cover w-full h-full rounded-lg"
                               />
                             </div>
                           </div>
       
                        
                           <div className="bg-white shadow-lg p-4 rounded-lg w-full md:w-[calc(100%-1rem)] md:ml-0 mt-4 md:mt-0">
                             <p className="text-md md:text-lg">
                               {data[tabIndex].desc}
                             </p>
                           </div>
                         </div>
                       </div>
                     )}
                   </div> */}
            </Tabs>
          </div>

          <div className="w-full lg:w-[40%] p-4 flex items-center justify-center"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/student-online-young-guy.jpg"
              alt="Student"
              className="w-full h-auto text-center transform  hover:scale-105 transition duration-300 ease-in-out"
            />
          </div>
        </div>

        {/* Live Interactive sessions section  */}

        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-xl sm:text-3xl xl:text-3xl font-bold ">
                Live Interactive Sessions{" "}
              </p>
            </div>
          </h1>

        </div>

        {/* Table Section */}

        <div className="bg-white py-8 pt-6 mt-4 mb-8 mx-2 md:mx-4 lg:mx-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="p-4 overflow-x-auto mb-4">
            <table {...getTableProps()} className="min-w-full table-auto bg-white rounded-lg shadow-lg border border-blue-600" >
              <thead style={{ backgroundColor: 'rgb(33, 68, 145)' }}>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className="py-3 px-4 text-center text-sm md:text-lg font-semibold text-white border-b border-blue-300"

                      >
                        {column.render('Header')}
                        <span>
                          {sortBy.length > 0
                            ? column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="border-b border-blue-200 hover:bg-blue-50">
                      {row.cells.map(cell => {
                        const cellClass = cell.column.id === 'topic' || cell.column.id === 'subtopic' || cell.column.id === 'description'
                          ? 'py-2 text-sm text-blue-800 text-left sm:pl-2 md:p-2 lg:p-4'
                          : 'py-2 text-sm text-blue-800 text-center sm:pl-2 md:p-2 lg:p-4';
                        return (
                          <td {...cell.getCellProps()} className={cellClass}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

          </div>
        </div>


        {/* Benefits section */}
        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-xl sm:text-3xl xl:text-3xl font-bold ">
                Benefits {" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
        >
          <Tabs
            selectedIndex={tabIndex3}
            onSelect={(index) => setTabIndex3(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data3.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex3(index)} // Update tabIndex on click
                  className={`${tabIndex3 === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-800 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex3 === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-2 ${tabIndex3 === index ? "text-blue-500" : "text-gray-800"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </div>



        <div
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex3}
            onSelect={(index) => setTabIndex3(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data3.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick3(index)}// Update tabIndex on click
                  className={`${tabIndex3 === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-800 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-base md:text-xl mb-0 md:mb-2  ${tabIndex3 === index ? "text-blue-500" : "text-gray-800"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

          </Tabs>
        </div>





        {/* Who Should Attend? section */}
        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-xl sm:text-3xl xl:text-3xl font-bold ">
                Who Should Attend?{" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
        >
          <Tabs
            selectedIndex={tabIndex2}
            onSelect={(index) => setTabIndex2(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data2.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex2(index)} // Update tabIndex on click
                  className={`${tabIndex2 === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-800 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex2 === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-2 ${tabIndex2 === index ? "text-blue-500" : "text-gray-800"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </div>



        <div
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex2}
            onSelect={(index) => setTabIndex2(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data2.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick2(index)}// Update tabIndex on click
                  className={`${tabIndex2 === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-800 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-base md:text-xl mb-2 ${tabIndex2 === index ? "text-blue-500" : "text-gray-800"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

          </Tabs>
        </div>


        {/* How to Register section */}
        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-xl sm:text-3xl xl:text-3xl font-bold ">
                How to Register{" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
        >
          <Tabs
            selectedIndex={tabIndex5}
            onSelect={(index) => setTabIndex5(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data5.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex5(index)} // Update tabIndex on click
                  className={`${tabIndex5 === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-800 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex5 === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-2 ${tabIndex5 === index ? "text-blue-500" : "text-gray-800"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </div>



        <div
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex2}
            onSelect={(index) => setTabIndex5(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data5.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick5(index)}// Update tabIndex on click
                  className={`${tabIndex5 === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-800 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-base md:text-xl mb-0 md:mb-2  ${tabIndex5 === index ? "text-blue-500" : "text-gray-800"
                        }`}
                    >
                      <span
                        className="bullet-point "
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}

                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

          </Tabs>
        </div>



        <Clientele />
        <SuccessStoryCarousel />
        <StudentTestimonials />
        <Footer />
        <StickyElements />

        <div className="fixed bottom-4 right-1 md:right-4 z-50 m-4">
          <a
            href="https://forms.gle/sfABidkhJ8AhAG6SA" // Replace with your actual Google Form link
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-[#e76a4b] to-[#f35831] text-black  font-semibold p-4 rounded-md shadow-lg hover:from-[#f35831] hover:to-[#e76a4b] transition duration-300"            >
            Register for Workshop
          </a>
        </div>
      </div >
    </>
  );
};

export default WorkshopsPage;
