import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

const MyExamo = () => {
  useEffect(() => {
    document.title = "My Examo - Think Quotient";
    AOS.init();
  }, []);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    adaptiveHeight: true,
    slidesToScroll: 1,
    // prevArrow: <div className="slick-arrow slick-prev ">&#x2190;</div>,
    // nextArrow: <div className="slick-arrow slick-next">&#x2192;</div>,
  };

  const visionMissionContent = [
    {
      title: "Create Your Own Tests for Your Students ",
      description:
        "With MyExamo, you have complete control over creating and managing tests for your students:",
      list: [
        "Prepare Tests According to Your Requirements:Create tests by subject or unit, tailored to your curriculum.",
        "Set Rules and Weightage:Customize test rules and subject/topic weightage.",
        "Save Test Papers:Keep test papers for future reference.",
        "Rapid Review:Provide immediate feedback to students after the test.",
        "Result Options:Set result release dates or allow students to view results immediately.",
        "Total Control: MyExamo gives you overall control over tests and results",
      ],
      image: "/assets/images/cyots.jpg",
    },
    {
      title: "Self-analyzing for Students ",
      description: "Empower your students to take charge of their learning: ",
      list: [
        "Student-Created Tests: Let students create tests to sharpen their skills.",
        "Flexible Test Rules: Students can set their own test rules with various options. ",
        "Instant Solutions: Students can access solutions for each question immediately. ",
        "Question Queries: Students can send questions related queries to you.  ",
        "Time Analysis: Students can view the time required to solve each question.  ",
      ],
      image: "/assets/images/sas.jpg",
    },
    {
      title: "Be a Synergistic Teacher ",
      description: "Enhance your teaching capabilities with MyExamo: ",
      list: [
        "Study Material and More: Provide study material, class notes, solutions, and sample papers to students anytime, anywhere, in any file format. ",
        " Assignments and Guidance: Set assignments and offer project guidance to students.  ",
        "Resource Sharing: Share subject-related links with students. ",
      ],
      image: "/assets/images/bst.jpg",
    },
    {
      title: "Keep Your Students Updated 24/7",
      description:
        "Stay connected with your students through the Advance Notice Center:  ",
      list: [
        "Create and Control Notices: Easily create and manage important notices. ",
        " Track Notice Statistics: Keep tabs on notice engagement.  ",
        `Effective Communication: Use notices for updates like "All the best for tomorrow's test."  `,
      ],
      image: "/assets/images/kysu.jpg",
    },
    {
      title: "You Initiate Surveys, We Provide Systematic Feedback",
      description: `Gather valuable feedback to exceed your students' expectations: `,
      list: [
        ` Feedback Setup: Create feedback surveys tailored to your institution's needs.  `,
        " Control the Process: Manage and control the feedback collection process.  ",
        ` Graphical Analytics: Gain insights with graphical analytics.   `,
      ],
      image: "/assets/images/feedback.jpg",
    },
    {
      title: "Gigantic Question Banks",
      description: `Access a vast array of questions across subjects:  `,
      list: [
        ` Extensive Question Database: Get access to a large pool of questions, including aptitude and engineering/medical entrance exam questions.`,
        "  Top Institute Questions: Add questions from top institutes to your set.   ",
        `  Free Access: Initially, we offer 4500+ aptitude questions and 30,000+ engineering and medical entrance exam questions, all accessible for free.  `,
      ],
      image: "/assets/images/gqb.jpg",
    },
    {
      title: "Easy and Effortless Question Uploading ",
      description: `Bring your own questions to MyExamo:   `,
      list: [
        ` Simple Upload Process: Easily upload your questions.`,
        " Multiple Questions: Use a simple Word file to upload multiple questions.   ",
        ` Rich Content Support: Upload questions with images, complex math equations, and special characters effortlessly.  `,
      ],
      image: "/assets/images/easyq.jpg",
    },
    {
      title: "We Understand Hierarchy ",
      description: `MyExamo recognizes the importance of hierarchy within your institution:  `,
      list: [
        `  Institute Head/Principal `,
        "Branch Head/Class Teacher   ",
        `  Teacher  `,
        `  Student  `,
      ],
      image: "/assets/images/understand-h.jpg",
    },
  ];

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>
        {/* Page Title */}
        {/* <title>{pageTitle}</title> */}
        <meta name="title" content={metaTitle} />

        {/* Remaining Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Facebook Pixel Code */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '473030589157148');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>{`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=473030589157148&ev=PageView&noscript=1"/>
        `}</noscript>

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []; 
          function gtag() { 
            dataLayer.push(arguments); 
          } 
          gtag("js", new Date()); 
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>

      </Helmet>

      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div className="flex">
          <div className="examo-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-3xl font-semibold mb-4 ">
                  Elevate Assessments with Revolutionizing Online Exams and MCQ
                  Evaluations for Seamless Learning
                </h1>

                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-3xl ">
                Your Path to Revolutionize Online Education
              </p>
              <br />
              <p className="font-bold text-2xl ">
                Introducing My Examo by ThinkQuotient
              </p>
            </div>
            <br /> A cutting-edge online exam product designed to transform the
            way assessments are conducted. Seamlessly catering to MCQs and online
            exams, My Examo brings a new dimension to learning evaluation. With a
            user-friendly interface, robust features, and advanced security
            measures, it ensures a smooth and secure examination experience for
            both educators and students. Say goodbye to traditional evaluation
            methods and embrace the future of assessments with My Examo.
          </h1>
        </div>

        <div className="md:flex  justify-evenly">
          <div
            className="counter-item text-center"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="h-36 flex items-center justify-center ">
              <img src="/assets/images/growth.svg" className=" w-28" />
            </div>
            <p className="font-bold text-2xl w-5/6 mx-auto py-4">
              Fueling Growth & Innovation
            </p>
          </div>

          <div className="counter-item text-center"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="h-36 flex items-center justify-center ">
              <img src="/assets/images/targeting.svg" className=" w-28 " />
            </div>
            <p className="font-bold text-2xl w-4/6 mx-auto py-4">
              Empowering Business of Every Size to Soar
            </p>
          </div>
        </div>

        {/* Vision & Mission Slider */}
        <div
          className="container mx-auto m-8 about-vm-section pb-20"
          id="tab-top-section"
        >
          <Slider {...sliderSettings} className="relative pt-12">
            {visionMissionContent.map((section, sectionIndex) => (
              <div key={sectionIndex} className="">
                <h1 className="md:text-2xl font-bold md:w-1/4 underline flex items-start ">
                  <svg
                    className="md:w-8 w-4  mr-6"
                    viewBox="-5 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    sketch="http://www.bohemiancoding.com/sketch/ns"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        type="MSPage"
                      >
                        <g
                          id="Icon-Set"
                          type="MSLayerGroup"
                          transform="translate(-473.000000, -1195.000000)"
                          fill="#000000"
                        >
                          <path
                            d="M486.717,1206.22 L474.71,1195.28 C474.316,1194.89 473.678,1194.89 473.283,1195.28 C472.89,1195.67 472.89,1196.31 473.283,1196.7 L484.566,1206.98 L473.283,1217.27 C472.89,1217.66 472.89,1218.29 473.283,1218.69 C473.678,1219.08 474.316,1219.08 474.71,1218.69 L486.717,1207.75 C486.927,1207.54 487.017,1207.26 487.003,1206.98 C487.017,1206.71 486.927,1206.43 486.717,1206.22"
                            id="chevron-right"
                            type="MSShapeGroup"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  {section.title}
                </h1>
                <div className="md:flex ">
                  <img
                    src={section.image}
                    alt={`Vision/Mission ${sectionIndex + 1}`}
                    className="pslider-box-img my-20 md:px-0 px-10 object-cover"
                  />
                  <div className="pslider-box flex  flex-col justify-center ">
                    <p className="md:ps-14 ps-4 font-semibold md:w-2/3 text-lg mb-6 p-4">
                      {section.description}
                    </p>
                    {section.list.map((item, index) => (
                      <p
                        key={index}
                        className="md:ms-16 ms-4 ps-6 text-md  w-2/3  list-items my-5"
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default MyExamo;
