import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import ApiPath from "./Apiurl";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";





const ThankYouPageCampaign = () => {

    const location = useLocation();
    const { name } = location.state || {};


    return (
        <>
            <div className=" bg-black relative p-4 ">
                <a href="https://thinkquotient.com/" rel="noopener noreferrer" className="inline-block">
                    <img
                        src="/assets/images/tq-logo.png"
                        alt="Logo"
                        className="h-12 md:h-16 w-auto cursor-pointer "
                    />
                </a>
            </div>

            <div className="flex justify-center items-center p-2 lg:p-6 mt-8 mb-20">
                <div className="flex flex-col lg:flex-row justify-between w-full  ">
                    {/* Image Section */}
                    <div className="lg:w-1/2 p-4 self-center"
                        data-aos="fade-right"
                        data-aos-duration="1000">
                        <div className="transform transition-transform duration-300 hover:scale-105  rounded-lg overflow-hidden">
                            <img
                                src="/assets/images/pretty-cheerful-business-woman-showing-thumbs-up.jpg"
                                alt="Thank You"
                                className="w-full h-auto max-w-xl mx-auto rounded-lg transition-all duration-300 ease-in-out"
                            />
                        </div>
                    </div>

                    {/* Text Section */}
                    <div className="lg:w-1/2 p-2 lg:p-6 text-center  lg:text-left"
                        data-aos="fade-left"
                        data-aos-duration="1000">
                        <h1 className="text-2xl md:text-3xl xl:text-4xl font-bold my-6 text-black leading-tight">
                            Thanks {name} for filling out the Enquiry Form!
                        </h1>
                        <p className="text-lg lg:text-xl mb-4 font-light text-gray-800">
                            We truly appreciate you taking the time to reach out to us.
                        </p>
                        <p className="text-base lg:text-lg font-medium text-gray-600">
                            Your message has been received, and our team will review it
                            shortly. We will get back to you as soon as possible, typically
                            within 1-2 business days.
                        </p>
                        <p className="mt-6 text-lg font-semibold text-indigo-600">
                            Thank you for your patience!
                        </p>
                    </div>
                </div>
            </div>
            <div className="bottom-footer md:flex text-white  justify-center  px-4 md:px-20 italic py-6 text-center">
                <p>&copy; Copyright 2025 by ThinkQuotient </p>
            </div>


        </>
    );
};

export default ThankYouPageCampaign;