import React, { useEffect, useRef, useState, } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';
import "react-tabs/style/react-tabs.css";
import SuccessStoryCarousel from "../../Components/Home/SuccessStoryCarousel";
import StickyElements from "./StickyElements";
import StudentTestimonials from "./StudentTestimonials";
import Clientele from "./Clientele";
import ExperienceTrainerBannerSection from "./ExperienceTrainerBannerSection";


const ExperienceTraining = () => {

    const webDevelopmentRef = useRef(null);
    const softwareDevelopmentRef = useRef(null);
    const appDevelopmentRef = useRef(null);


    const [loading, setLoading] = useState(false);

    const pdfPaths = ["/assets/images/Revised Brochure2024.pdf"];

    const downloadPDF = (pdfPaths) => {
        pdfPaths.forEach((pdfPath) => {
            const link = document.createElement("a");
            link.href = pdfPath;
            link.download = pdfPath.split("/").pop();
            link.click();
        });
    };

    const location = useLocation();

    // Extract the query parameter value
    const searchParams = new URLSearchParams(location.search);
    const flag = searchParams.get('flag');
    useEffect(() => {
        document.title = "Experience Upskilling - Think Quotient";
        AOS.init();
    }, []);

    useEffect(() => {
        // Get the entire hash string, including the '?'
        const hashString = window.location.hash;

        // Extract the path and query parameters
        const [path, queryString] = hashString.slice(2).split('?'); // Remove the leading '#/' and split at '?'

        // If you need to parse the query parameters, you can use URLSearchParams
        if (queryString) {
            const queryParams = new URLSearchParams(queryString);
            const scrollToSection = queryParams.get('scrollTo');

            // Scroll to the specified section
            if (scrollToSection) {
                const targetSection = document.getElementById(scrollToSection);

                if (targetSection) {
                    targetSection.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }

        // Rest of your useEffect logic
        console.log("------------->", flag);
        console.log(location.state);
    }, []);



    const pageTitle = "Experience Upskilling - ThinkQuotient";
    const metaTitle = "Best IT Training Course with Drive Assistance";
    const helmetContent = getHelmetContent();



    useEffect(() => {

        AOS.init();

    }, []);

    useEffect(() => {
        // Get the entire hash string, including the '?'
        const hashString = window.location.hash;

        // Extract the path and query parameters
        const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

        // If you need to parse the query parameters, you can use URLSearchParams
        if (queryString) {
            const queryParams = new URLSearchParams(queryString);
            const scrollToSection = queryParams.get("scrollTo");
            console.log("hello " + scrollToSection);
            // Scroll to the specified section
            if (scrollToSection) {
                const targetSection = document.getElementById(scrollToSection);

                if (targetSection) {
                    targetSection.scrollIntoView({ behavior: "smooth" });
                }
            }
        }

        // Rest of your useEffect logic
    }, []);

    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const [activeTab, setActiveTab] = React.useState("tab1");
    const [tabIndex, setTabIndex] = React.useState(0);
    const tabRef = useRef(null); // Create a ref for the tab content


    const data = [
        {
            count: "01",
            label: "Training by Industry Experts with Placement Assistance",
            value: "tab1",
            image: "",
            desc: ` `,
        },

        {
            count: "02",
            label: "Focus on Hands-on Practice",
            value: "tab2",
            image: "",
            desc: ``,
        },
        {
            count: "03",
            label: "Exposure to Live Projects",
            image: "",
            value: "tab3",
            desc: ``,
        },
        {
            count: "04",
            label: "Grooming for interview preparation",
            image: "",
            value: "tab4",
            desc: ``,
        },
        {
            count: "05",
            label: "2000+ Students Succesfully Placed",
            value: "tab5",
            image: "",
            desc: ``,
        },
        {
            count: "06",
            label: "1 year Support for Placement Opportunities",
            value: "tab6",
            image: "",
            desc: ``,
        },
    ];

    const skills = [
        "Python ML Generative AI",
        "Data Analytics KNIME",
        "Java Spring Boot",
        "C# .NET Core",
        "Angular",
        "React JS",
        "React Native Mobile Programming",
        "Cybersecurity",
        "QA Manual + Automation"
    ];

    const [activeTab1, setActiveTab1] = React.useState("tab1");
    const [tabIndex1, setTabIndex1] = React.useState(0);

    const handleTabClick1 = (index) => {
        setTabIndex1(index);

    };

    const data1 = [
        {
            count: "01",
            label: "Upgrading is a necessary activity nowadays, especially considering the cost-cutting scenarios across organizations.",
            value: "tab1",
            image: "",
            desc: ` `,
        },

        {
            count: "02",
            label: "Live interactive training is what you need—where a trainer guides you through live sessions.",
            value: "tab2",
            image: "",
            desc: ``,
        },
        {
            count: "03",
            label: "Online training is easy to attend from the comfort of your own space.",
            image: "",
            value: "tab3",
            desc: ``,
        },
        {
            count: "04",
            label: "Affordable cost and flexibility for the audience. The training can be selected for either 1 month or 3 months, based on your needs and availability.",
            image: "",
            value: "tab4",
            desc: ``,
        },
        {
            count: "05",
            label: "Placement assistance is an additional benefit, helping you apply for higher-package opportunities.",
            value: "tab5",
            image: "",
            desc: ``,
        },
    ];

    return (
        <>
            <Helmet>
                {/* Basic Meta Tags */}
                <title>{"Online Courses - Learn Anything"}</title>
                <meta
                    name="description"
                    content="Jumpstart your IT career with Think Quotient's Freshers Training Program. Master web development, software development, app development, and work on live projects. Join over 2,000 successful students today! IT courses near me, java course, KNIME course near me, java full stack developer course, full stack java developer course, java online course, learn java programming, java classes near me, java full course, oracle java certification, sap classes near me, java script course, java language course, java course near me, java online classes, iit coaching near me, complete java course, java programming basics, java programming course online, full stack java developer training, computing courses near me, best computer institute near me, java programming language course, java scripting course, java language course online, java programming language online course, classes java, java course cost, java programming for interview, knime sql certification."
                />
                <meta
                    name="keywords"
                    content="e-learning website, best e learning platform, blended learning platform, learn artificial intelligence, machine learning, data science, python, full stack development, java, C#, Dotnet, Testing, QA, React, Angular, think quotient"
                />

                {/* Dynamic Meta Tags */}
                {helmetContent?.meta.map((metaTag, index) => (
                    <meta key={index} name={metaTag.name} content={metaTag.content} />
                ))}

                {/* Social Media Sharing */}
                <meta property="og:title" content={metaTitle || "Online Courses - Learn Anything"} />
                <meta
                    property="og:description"
                    content="Join our Freshers Training program to excel in IT. Learn web, software, and app development with hands-on projects. Trusted by over 2000 placed students."
                />
                <meta
                    property="og:image"
                    content="https://www.thinkquotient.ai/assets/images/freshers-training-banner.png"
                />
                <meta property="og:url" content="https://www.thinkquotient.ai/training/fresherstraining" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={metaTitle || "Freshers Training - Think Quotient"} />
                <meta
                    name="twitter:description"
                    content="Learn essential IT skills with Think Quotient. Hands-on training in web, software, and app development with real projects."
                />
                <meta
                    name="twitter:image"
                    content="https://www.thinkquotient.ai/assets/images/freshers-training-banner.png"
                />

                {/* Robots and Author */}
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Think Quotient" />

                {/* Facebook Pixel Code */}
                <script>
                    {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '473030589157148');
                    fbq('track', 'PageView');
                    `}
                </script>
                <noscript>
                    {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=473030589157148&ev=PageView&noscript=1"/>
                    `}
                </noscript>

                {/* Updated Google Tag Manager */}
                <script>
                    {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KTNBVH9V');
    `}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-143016865-1");
    `}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-BSFPPN5E9D');
    `}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-574047969');
    `}
                </script>

                {/* Google Tag Manager Noscript */}
                <noscript>
                    {`
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
    `}
                </noscript>
            </Helmet>


            <div className="overflow-hidden">

                {/* Header Section */}
                <Header />

                <ExperienceTrainerBannerSection />

                <div className="flex flex-col items-center py-14  fade-in-down px-6">
                    <h1
                        className="text-lg font-normal container mx-auto text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        <div>
                            <p className="font-bold text-2xl xl:text-4xl font-serif   ">
                                Training Programs with ThinkQuotient {" "}
                            </p>
                        </div>
                    </h1>
                </div>
                <div className="mx-0 xl:mx-4 mb-4 md:mb-8">
                    <div className="skills-container  bg-gradient-to-br from-blue-300 via-white to-teal-200  p-8 rounded-lg shadow-xl  mx-auto text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">

                        <h2 className="skills-header text-gray-800 font-bold text-3xl mb-6 lg:mb-12">Training Expertise</h2>
                        <div className="skills-list flex justify grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 justify-between">
                            {skills.map((skill, index) => (
                                <div key={index} className="skill-item bg-gradient-to-br from-gray-400 to-blue-600 p-6 rounded-lg shadow-lg  w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg min-h-[120px] flex justify-center items-center">
                                    <p className="text-base md:text-lg font-semibold text-white text-center">{skill}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>



                <div className="flex flex-col items-center pt-14   fade-in-down px-6">
                    <h1
                        className="text-lg font-normal container mx-auto text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        <div>
                            <p className="text-xl sm:text-3xl xl:text-3xl font-bold ">
                                Benefits  {" "}
                            </p>
                        </div>
                    </h1>
                </div>

                <div className="flex flex-col lg:flex-row mt-6"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    <div className="w-full lg:w-[60%] container  mx-auto py-14 lg:py-8 px-4 hidden  lg:block"
                        ref={tabRef} >
                        <ul>
                            {data1.map(({ label }, index) => (
                                <li
                                    key={index}
                                    className="flex items-start py-2 group transition-transform duration-200 ease-in-out hover:scale-105"
                                >
                                    <span
                                        className="mt-2 w-[3px] h-[30px] bg-[#01a8ef] transition-all duration-200 ease-in-out group-hover:w-[5px] group-hover:h-[50px] group-hover:bg-blue-500"
                                    ></span>
                                    <p
                                        className="ml-4 font-semibold text-lg md:text-xl mb-2 text-gray-700 transition-colors duration-200 ease-in-out group-hover:text-blue-500"
                                    >
                                        {label}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>



                    <div className="container mx-auto py-10 px-6 md:px-10 lg:px-4 md:block lg:hidden">
                        <ul>
                            {data1.map(({ label }, index) => (
                                <li
                                    key={index}
                                    className="flex items-start py-2 group transition-transform duration-200 ease-in-out hover:scale-110"
                                >
                                    <span
                                        className="mt-2 w-[3px] h-[30px] bg-[#01a8ef] transition-all duration-200 ease-in-out group-hover:w-[5px] group-hover:h-[50px] group-hover:bg-blue-500"
                                    ></span>
                                    <p
                                        className="ml-4 font-semibold text-lg md:text-xl mb-2 text-gray-700 transition-colors duration-200 ease-in-out group-hover:text-blue-500"
                                    >
                                        {label}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="w-full lg:w-[40%] p-4 flex items-center justify-center"
                        data-aos="fade-left"
                        data-aos-duration="1000">
                        <img
                            src="/assets/images/person-working-html-computer.jpg"
                            alt="Student"
                            className="w-full h-auto text-center transform  hover:scale-105 transition duration-300 ease-in-out"
                        />
                    </div>
                </div>




                {/* <div className="flex flex-col xl:flex-row gap-6 mx-4 lg:gap-10 mt-6 mb-8">
 
  <div
    className="relative bg-gradient-to-br from-blue-500 to-purple-400 text-white rounded-lg shadow-xl p-8 xl:w-1/2 hover:scale-105 transition-transform duration-300"
    data-aos="fade-right"
    data-aos-duration="1000"
  >
    
    <h3 className="text-3xl font-bold mb-4">
      Intelligence-based Full Stack Program
    </h3>
    <p className="text-white text-opacity-90 mb-6 leading-relaxed">
      Dive into our comprehensive full-stack program tailored to enhance your
      intelligence-based programming skills. Build innovative, scalable
      solutions with cutting-edge technologies.
    </p>
  
  </div>

  
  <div
    className="relative bg-gradient-to-br from-green-500 to-teal-400 text-white rounded-lg shadow-xl p-8 xl:w-1/2 hover:scale-105 transition-transform duration-300"
    data-aos="fade-left" 
    data-aos-duration="1000"
  >
   
    <h3 className="text-3xl font-bold mb-4">
      Gen AI & Germany-based KNIME Program
    </h3>
    <p className="text-white text-opacity-90 mb-6 leading-relaxed">
      Join the industry-leading training program focused on Generative AI and
      KNIME. Designed for data enthusiasts and professionals, this program
      provides advanced insights into data analytics and AI applications.
    </p>
    
  </div>
</div>


        <div className="flex flex-col xl:flex-row gap-4 mx-4 lg:gap-8">
          <div className="w-full xl:w-1/2 h-auto" data-aos="fade-right" data-aos-duration="1000">
            <img
              src="/assets/images/internp1.png"
              alt="Fullstack Training Program"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full xl:w-1/2 h-auto" data-aos="fade-left" data-aos-duration="1000">
            <img
              src="/assets/images/Generativ_AI_Knime.png"
              alt="Generative AI & KNIME Training Program"
              className="w-full h-full object-cover"
            />
          </div>
        </div> */}

                <div className="flex flex-col items-center py-14  fade-in-down px-6">
                    <h1
                        className="text-lg font-normal container mx-auto text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        <div>
                            <p className="font-bold text-2xl xl:text-4xl font-serif   ">
                                Technology Training Programs with Live Projects{" "}
                            </p>
                        </div>
                    </h1>
                </div>

                {/* text and image box */}

                {/* First */}
                <div className="m-0 flex flex-col xl:flex-row  mx-0 lg:mx-4 px-0 lg:px-4 gap-x-8 justify-between">
                    {/* Tabs Section */}
                    <div className="">
                        <div className="flex flex-col items-center py-14   fade-in-down px-6">
                            <h1
                                className="text-lg font-normal container mx-auto text-center"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                            >
                                <div>
                                    <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                                        Features{" "}
                                    </p>
                                </div>
                            </h1>
                        </div>
                        <ul className="list-none mx-6 md:mx-10" data-aos="fade-right"
                            data-aos-duration="1000">
                            {data.map(({ label }, index) => (
                                <li
                                    key={index}
                                    className="flex items-center my-8 transition-transform duration-200 ease-in-out hover:scale-105 cursor-pointer"
                                >
                                    <span
                                        className="w-[3px] h-[30px] bg-[#01a8ef] mr-4 transition-all duration-200 ease-in-out hover:w-[5px] hover:h-[40px] hover:bg-blue-500"
                                    ></span>
                                    <p className="font-semibold text-lg md:text-xl text-gray-700 transition-colors duration-200 ease-in-out hover:text-blue-500">
                                        {label}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        {/* Blinking button at the bottom */}
                        {/* <div className="mt-12 flex justify-center">
              <button
                className="btn-style-1 text-xl mt-2 p-4  animate-blink hover:animate-none "
                onClick={() => downloadPDF(pdfPaths)}
              >
                Download Brochure
              </button>
            </div> */}
                    </div>

                    <div
                        className="xl:w-1/2 md:p-10 p-10 flex flex-col justify-center bg-white"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                    >
                        <div>
                            <h2 className="text-3xl sm:text-5xl xl:text-3xl font-bold text-center mb-16 mt-4">
                                Technologies
                            </h2>
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 gap-y-8 ">
                            {/* Image 1 */}
                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/java.svg"
                                        alt="Java"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">Java</p>
                            </div>

                            {/* Image 2 */}
                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/dotnet.1024x1024 (1).png"
                                        alt="DotNet"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">DotNet</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/c.svg"
                                        alt="DotNet"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">C++</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/spring.svg"
                                        alt="SpringBoot"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">SpringBoot</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/hibernate-icon.svg"
                                        alt="Hibernate"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">Hibernate</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/sql.svg"
                                        alt="SQL"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">SQL</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/angular.svg"
                                        alt="Angular"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">Angular</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/react.svg"
                                        alt="React"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">React</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/KnimeImg.png"
                                        alt="KNIME"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">KNIME</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/python.svg"
                                        alt="Python"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">Python</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/data-science.svg"
                                        alt="Data Science"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">Data Science</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                                    <img
                                        src="/assets/images/artificial-intelligence.png"
                                        alt="Data Science"
                                        className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                                    />
                                </div>
                                <p className="mt-4 text-center text-xl">GenAI</p>
                            </div>

                        </div>


                    </div>
                    {/* <div
            className="lg:w-1/2 border-2 border-gray-200 "
            data-aos="fade-left"
            data-aos-duration="1000"
            id="web-development"
          >
            <img
              src="/assets/images/internp.png"
              className="object-contain w-full h-full"
            />
          </div> */}
                </div>





                <Clientele />
                <SuccessStoryCarousel />
                <StudentTestimonials />
                <Footer />
                <StickyElements />

            </div>
        </>
    );
};

export default ExperienceTraining;
