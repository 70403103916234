import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { FaFacebook, FaFacebookF, FaInstagram, FaLinkedin, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const Header = () => {
  const location = useLocation();
  const [isSticky, setSticky] = useState(false);
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isProductsOpen, setProductsOpen] = useState(false);
  const [isInsightsOpen, setInsightsOpen] = useState(false);
  const [isOfferingsOpen, setOfferingsOpen] = useState(false);
  const [isCareersOpen, setCareersOpen] = useState(false);
  const [isTrainingOpen, setTrainingOpen]= useState(false);




  const handleClick = () => {
    // Check if the current location is the home page
    console.log(location.pathname);
    if (location.pathname === '/' || location.pathname === '/contact') {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // For other pages, the Link component will handle the navigation
  };

  const toggleProducts = () => {
    setProductsOpen(!isProductsOpen);
  };

  const toggleServices = () => {
    setServicesOpen(!isServicesOpen);
  };

  const toggleInsights = () => {
    setInsightsOpen(!isInsightsOpen);
  };

  const toggleOfferings = () => {
    setOfferingsOpen(!isOfferingsOpen);
  };

  const toggleCareers = () => {
    setCareersOpen(!isCareersOpen);
  };

  const toggleTraining=() => {
    setTrainingOpen(!isTrainingOpen);
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setSticky(offset > 400);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  // Mobile menu
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  
 const [isFreshersUpskillingOpen, setFreshersUpskillingOpen] = useState(false); // State to toggle visibility

 const toggleFreshersUpskilling = () => {
   setFreshersUpskillingOpen(!isFreshersUpskillingOpen);
 };

  return (
    <div
      className={`w-full z-50 bg-white shadow-sm  ${isSticky ? "sticky-header slide-in-top" : ""
        }`}
    >
      {/* Large Screen Navbar */}
      <div className="bg-black text-white py-2 px-4 main-nav">
        <div className="container mx-auto flex items-center justify-center md:justify-between">
          <div className="flex items-center justify-between space-x-1">
            <p className="md:placeholder:mr-4 md:text-sm text-xs">
              Call:{" "}
              <a href="tel:+917030055128" className="header-contact-hover-1">
                +91-7030055128
              </a>
            </p>
            <p className="md:mr-4 ">|</p>
            <p className="mr-4 md:text-sm text-xs">
              Email:{" "}


              <a
                href="mailto:support@thinkquotient.com"
                className="header-contact-hover-2"
              >
                support@thinkquotient.com
              </a>
            </p>
          </div>
          <div className="md:flex items-center  hidden">
            {/* <span className="mr-4">
              <a href="https://www.instagram.com/thinkquotient_software/" target="_blank"><img
                src="/assets/images/insta.svg"
                alt="Instagram"
                className="skyblue-image hover1"
              /></a>
            </span>
            <span className="mr-4">
              <a href="https://www.linkedin.com/company/thinkquotient/mycompany/?viewAsMember=true" target="_blank"><img
                src="/assets/images/linkedin.svg"
                alt="Twitter"
                className="skyblue-image w-6 hover2"
              /></a>
            </span>
            <span className="mr-4">
              <a href=" https://www.youtube.com/@thinkquotient1429" target="_blank"><img
                src="/assets/images/youtube.svg"
                alt="Youtube"
                className="skyblue-image w-6 hover3"
              /></a>
            </span>
            <a href=" https://www.facebook.com/ThinkQuotient" target="_blank"><img
              src="/assets/images/facebook.svg"
              alt="Facebook"
              className="skyblue-image hover4"
            /></a> */}

            <span className="mr-4">
              <a href="https://www.instagram.com/thinkquotient_software/" target="_blank">
                <FaInstagram className="skyblue-image" />
              </a>
            </span>
            <span className="mr-4">
              <a href="https://www.linkedin.com/company/thinkquotient?originalSubdomain=in" target="_blank">
                <FaLinkedinIn className="skyblue-image" />
              </a>
            </span>
            <span className="mr-4">
              <a href=" https://www.youtube.com/@thinkquotient1429" target="_blank">
                <FaYoutube className="skyblue-image" />
              </a>
            </span>
            <span className="mr-4">
              <a href=" https://www.facebook.com/ThinkQuotient" target="_blank">
                <FaFacebookF className="skyblue-image" />
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="container mx-auto flex items-center justify-between py-4 px-4 md:px-0 main-nav">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="/assets/images/envelop.png"
              alt="Main Logo"
              className="md:w-full object-contain h-14 mr-4"
              onClick={handleClick}
            />
          </Link>
        </div>
        <nav className="md:flex items-center justify-end navbar md:flex-grow hidden ">
          <ul className="flex space-x-1">
            <Link to="/">
              <li className={`nav-item ${isActiveRoute("/") && "active-nav"}`}>
                Home
              </li>
            </Link>
            <Link to="/about">
              <li
                className={`nav-item ${isActiveRoute("/about") && "active-nav"
                  }`}
              >
                About
              </li>
            </Link>
            <li
              className={`nav-item services relative ${(
                isActiveRoute("/training/fresherstraining") ||
                isActiveRoute("/training/corporatetraining") ||
                isActiveRoute("/training/generativeAI-KNIMETraining"))  &&
                "active-nav"
                }`}
              onMouseEnter={toggleTraining}
              onMouseLeave={toggleTraining}
            >
              <span className="nav-item-link">
                Upskilling {isTrainingOpen ? "-" : "+"}
              </span>

              {isTrainingOpen && (
                <ul className="submenu fixed shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2 rounded-md text-white fade-in-up z-50">
          
                  <li className={`submenu-item ${isActiveRoute("/training/fresherstraining") && "active-submenu"}`}>
                    <span onClick={toggleFreshersUpskilling} className="toggle-button">
                      Freshers Upskilling
                      {isFreshersUpskillingOpen ? " - " : " + "}
                    </span>
                    {/* Submenu shown or hidden based on the state */}
                    {isFreshersUpskillingOpen && (
                      <ul className="submenu-level-2 show">
                        <Link to="/training/fresherstraining">
                          <li className={`submenu-item ${isActiveRoute("/training/fresherstraining") && "active-submenu"}`}>
                            Fullstack Upskilling
                          </li>
                        </Link>
                        <Link to="/training/generativeAI-KNIMETraining">
                          <li className={`submenu-item ${isActiveRoute("/training/generativeAI-KNIMETraining") && "active-submenu"}`}>
                            Generative AI & KNIME Upskilling
                          </li>
                        </Link>
                      </ul>
                    )}
                  </li>

                  <Link to="/training/experienceupskilling">
                    <li
                      className={`submenu-item ${isActiveRoute("/training/experienceupskilling") &&
                        "active-submenu"
                        }`}
                    >
                      Experience Upskilling
                    </li>
                  </Link>

                  <Link to="/training/corporatetraining">
                    <li
                      className={`submenu-item ${isActiveRoute("/training/corporatetraining") &&
                        "active-submenu"
                        }`}
                    >
                      Corporate Upskilling
                    </li>
                  </Link>
                  {/* <Link to="/training/generativeAI-KNIMETraining">
                    <li
                      className={`submenu-item ${isActiveRoute("/training/generativeAI-KNIMETraining") &&
                        "active-submenu"
                        }`}
                    >
                      Generative AI & KNIME Training
                    </li>
                  </Link> */}
                </ul>
              )}
            </li>

            <li
              className={`nav-item services relative ${(isActiveRoute("/services/productdevelopment") ||
                isActiveRoute("/services/technologyconsulting") ||
                isActiveRoute("/services/outsourcing")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleServices}
              onMouseLeave={toggleServices}
            >
              <span className="nav-item-link">
                Services {isServicesOpen ? "-" : "+"}
              </span>
              {isServicesOpen && (
                <ul className="submenu fixed shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2 rounded-md text-white fade-in-up z-50">
                  <Link to="/services/productdevelopment">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/productdevelopment") &&
                        "active-submenu"
                        }`}
                    >
                      Products Development
                    </li>
                  </Link>
                  <Link to="/services/technologyconsulting">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/technologyconsulting") &&
                        "active-submenu"
                        }`}
                    >
                      Technology Consulting
                    </li>
                  </Link>
                  <Link to="/services/outsourcing">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/outsourcing") &&
                        "active-submenu"
                        }`}
                    >
                      Outsourcing
                    </li>
                  </Link>
                  <Link to="/services/knime">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/knime") && "active-submenu"
                        }`}
                    >
                      KNIME
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <Link to="/knimeInfo">
              <li className={`nav-item ${isActiveRoute("/knimeInfo") && "active-nav"}`}>
              KNIME
              </li>
            </Link>
             <li
              className={`nav-item platforms-products relative ${(isActiveRoute("/platforms&products/hlt") ||
                isActiveRoute("/platforms&products/videosystem") ||
                isActiveRoute("/platforms&products/myexamo") ||
                isActiveRoute("/platforms&products/lms")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleProducts}
              onMouseLeave={toggleProducts}
            >
              <span className="nav-item-link">
                Platforms & Products {isProductsOpen ? "-" : "+"}
              </span>
              {isProductsOpen && (
                <ul className="submenu fixed shadow-lg top-24 primary-bg-color ps-6 pe-16 pt-4 pb-2 rounded-md text-white fade-in-up z-50">
                  <Link to="/platforms&products/myexamo">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/myexamo") &&
                        "active-submenu"
                        }`}
                    >
                      My Examo
                    </li>
                  </Link>
                  <Link to="/platforms&products/hlt">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/hlt") &&
                        "active-submenu"
                        }`}
                    >
                      HLT
                    </li>
                  </Link>
                  <Link to="/platforms&products/videosystem">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/videosystem") &&
                        "active-submenu"
                        }`}
                    >
                      Video System
                    </li>
                  </Link>
                  <Link to="/platforms&products/lms">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/lms") &&
                        "active-submenu"
                        }`}
                    >
                      LMS
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <li
              className={`nav-item insights relative ${(isActiveRoute("/insights/tournaments") ||
                isActiveRoute("/insights/success-stories") ||
                isActiveRoute("/insights/webinars") ||
                isActiveRoute("/insights/brochures") ||
                isActiveRoute("/insights/white-papers") ||
                // isActiveRoute("/insights/news-announcements") ||
                isActiveRoute("/insights/csr")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleInsights}
              onMouseLeave={toggleInsights}
            >
              <span className="nav-item-link">
                Insights {isInsightsOpen ? "-" : "+"}
              </span>
              {isInsightsOpen && (
                <ul className="submenu fixed shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2 rounded-md text-white fade-in-up z-50">
                   <Link to="/insights/tournaments">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/tournaments") &&
                        "active-submenu"
                        }`}
                    >
                      Tournaments
                    </li>
                  </Link>
                  <Link to="/insights/success-stories">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/success-stories") &&
                        "active-submenu"
                        }`}
                    >
                      Success Stories
                    </li>
                  </Link>
                  <Link to="/insights/webinars">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/webinars") && "active-submenu"
                        }`}
                    >
                      Webinars
                    </li>
                  </Link>
                  <Link to="/insights/brochures">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/brochures") && "active-submenu"
                        }`}
                    >
                      Brochures
                    </li>
                  </Link>
                  <Link to="/insights/white-papers">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/white-papers") &&
                        "active-submenu"
                        }`}
                    >
                      White Papers
                    </li>
                  </Link>
                  {/* <Link to="/insights/news-announcements">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/news-announcements") &&
                        "active-submenu"
                        }`}
                    >
                      News & Announcements
                    </li>
                  </Link> */}
                  <Link to="/insights/csr">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/csr") && "active-submenu"
                        }`}
                    >
                      CSR
                    </li>
                  </Link>
                  <Link to="/insights/expectationOfHiring">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/expectationOfHiring") && "active-submenu"
                        }`}
                    >
                      Hiring expectations
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <li
              className={`nav-item offerings relative ${(isActiveRoute("/offerings/thinquotient-franchise") ||
                isActiveRoute("/offerings/channel-partner")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleOfferings}
              onMouseLeave={toggleOfferings}
            >
              <span className="nav-item-link">
                Offerings {isOfferingsOpen ? "-" : "+"}
              </span>
              {isOfferingsOpen && (
                <ul className="submenu fixed shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2 rounded-md text-white fade-in-up z-50">
                  <Link to="/offerings/thinquotient-franchise">
                    <li
                      className={`submenu-item ${isActiveRoute("/offerings/thinquotient-franchise") &&
                        "active-submenu"
                        }`}
                    >
                      ThinkQuotient Franchise
                    </li>
                  </Link>
                  <Link to="/offerings/channel-partner">
                    <li
                      className={`submenu-item ${isActiveRoute("/offerings/channel-partner") &&
                        "active-submenu"
                        }`}
                    >
                      Channel Partner
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            {/* <li className={`nav-item careers relative ${isCareersOpen && 'active-nav'}`} onMouseEnter={toggleCareers} onMouseLeave={toggleCareers}> */}
            <li
              className={`nav-item careers relative ${(isActiveRoute("/careers/work-with-us") ||
                isActiveRoute("/careers") ||
                isActiveRoute("/careers/ITFreshers")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleCareers}
              onMouseLeave={toggleCareers}
            >

              <span className="nav-item-link">Careers {isCareersOpen ? "-" : "+"}</span>
              {isCareersOpen && (
                <ul className="submenu fixed shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2 rounded-md text-white fade-in-up z-50">
                  <Link to="/careers/ITFreshers">
                    <li
                      className={`submenu-item ${isActiveRoute("/careers/ITFreshers") &&
                        "active-submenu"
                        }`}
                    >
                      IT Freshers
                    </li>
                  </Link>
                  <Link to="/careers/work-with-us">
                    <li
                      className={`submenu-item ${isActiveRoute("/careers/work-with-us") &&
                        "active-submenu"
                        }`}
                    >
                      Work With Us
                    </li>
                  </Link>
                </ul>
              )}

            </li>
            {/* <Link to="/contact">
              <li
                className={`nav-item ${isActiveRoute("/contact") && "active-nav"
                  }`}
              >
                Contact Us
              </li>
            </Link> */}
          </ul>
        </nav>

        {/* Mobile Navbar */}
        <div className="md:hidden flex items-center">
          <button
            className="text-dark-500 font-semibold focus:outline-none "
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>
        <nav
          className={`absolute right-0 top-0  justify-end flex items-center navbar md:hidden bg-black bg-opacity-60 w-full h-[100vh] z-50 ${isMobileMenuOpen ? "block" : "hidden"
            }`}
        >
          <ul className="flex flex-col space-y-4  bg-white slide-in-right h-full w-8/12 py-14">
            <button
              className="text-dark-500 font-semibold focus:outline-none z-50 absolute top-6 right-4"
              onClick={toggleMobileMenu}
            >
              <FiX size={28} />
            </button>
            <Link to="/">
              <li className={`nav-item ${isActiveRoute("/") && "active-nav"}`}>
                Home
              </li>
            </Link>
            <Link to="/about">
              <li
                className={`nav-item ${isActiveRoute("/about") && "active-nav"
                  }`}
              >
                About
              </li>
            </Link>
            <li
              className={`nav-item services relative ${(isActiveRoute("/training/fresherstraining") ||
                isActiveRoute("/training/corporatetraining")||
                isActiveRoute("/training/generativeAI-KNIMETraining")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleTraining}
              onMouseLeave={toggleTraining}
            >
              <span className="nav-item-link">
                Upskilling {isTrainingOpen ? "-" : "+"}
              </span>

              {isTrainingOpen && (
                <ul className="submenu  shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2  text-white fade-in-up z-50">
                    <li className={`submenu-item ${isActiveRoute("/training/fresherstraining") && "active-submenu"}`}>
                    <span onClick={toggleFreshersUpskilling} className="toggle-button">
                      Freshers Upskilling
                      {isFreshersUpskillingOpen ? " - " : " + "}
                    </span>
                    {/* Submenu shown or hidden based on the state */}
                    {isFreshersUpskillingOpen && (
                      <ul className="submenu-level-2 show">
                        <Link to="/training/fresherstraining">
                          <li className={`submenu-item ${isActiveRoute("/training/fresherstraining") && "active-submenu"}`}>
                            Fullstack Upskilling
                          </li>
                        </Link>
                        <Link to="/training/generativeAI-KNIMETraining">
                          <li className={`submenu-item ${isActiveRoute("/training/generativeAI-KNIMETraining") && "active-submenu"}`}>
                            Generative AI & KNIME Upskilling
                          </li>
                        </Link>
                      </ul>
                    )}
                  </li>

                  <Link to="/training/experienceupskilling">
                    <li
                      className={`submenu-item ${isActiveRoute("/training/experienceupskilling") &&
                        "active-submenu"
                        }`}
                    >
                      Experience Upskilling
                    </li>
                  </Link>

                  <Link to="/training/corporatetraining">
                    <li
                      className={`submenu-item ${isActiveRoute("/training/corporatetraining") &&
                        "active-submenu"
                        }`}
                    >
                      Corporate Upskilling
                    </li>
                  </Link>

                </ul>
              )}
            </li>

            <li
              className={`nav-item services relative ${(isActiveRoute("/services/productdevelopment") ||
                isActiveRoute("/services/technologyconsulting") ||
                isActiveRoute("/services/outsourcing")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleServices}
              onMouseLeave={toggleServices}
            >
              <span className="nav-item-link">
                Services {isServicesOpen ? "-" : "+"}
              </span>

              {isServicesOpen && (
                <ul className="submenu  shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2  text-white fade-in-up z-50">
                  <Link to="/services/productdevelopment">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/productdevelopment") &&
                        "active-submenu"
                        }`}
                    >
                      Products Development
                    </li>
                  </Link>
                  <Link to="/services/technologyconsulting">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/technologyconsulting") &&
                        "active-submenu"
                        }`}
                    >
                      Technology Consulting
                    </li>
                  </Link>
                  <Link to="/services/outsourcing">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/outsourcing") &&
                        "active-submenu"
                        }`}
                    >
                      Outsourcing
                    </li>
                  </Link>
                  <Link to="/services/knime">
                    <li
                      className={`submenu-item ${isActiveRoute("/services/knime") && "active-submenu"
                        }`}
                    >
                      KNIME
                    </li>
                  </Link>
                </ul>
              )}
            </li>

            <Link to="/knimeInfo">
                <li className={`nav-item ${isActiveRoute("/knimeNew") && "active-nav"}`}>
                  KNIME
                </li>
              </Link>

            <li
              className={`nav-item platforms-products relative ${(isActiveRoute("/platforms&products/hlt") ||
                isActiveRoute("/platforms&products/videosystem") ||
                isActiveRoute("/platforms&products/myexamo") ||
                isActiveRoute("/platforms&products/lms")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleProducts}
              onMouseLeave={toggleProducts}
            >
              <span className="nav-item-link">
                Platforms & Products {isProductsOpen ? "-" : "+"}
              </span>
              {isProductsOpen && (
                <ul className="submenu  shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2  text-white fade-in-up z-50">
                  <Link to="/platforms&products/myexamo">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/myexamo") &&
                        "active-submenu"
                        }`}
                    >
                      My Examo
                    </li>
                  </Link>
                  <Link to="/platforms&products/hlt">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/hlt") &&
                        "active-submenu"
                        }`}
                    >
                      HLT
                    </li>
                  </Link>
                  <Link to="/platforms&products/videosystem">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/videosystem") &&
                        "active-submenu"
                        }`}
                    >
                      Video System
                    </li>
                  </Link>
                  <Link to="/platforms&products/lms">
                    <li
                      className={`submenu-item ${isActiveRoute("/platforms&products/lms") &&
                        "active-submenu"
                        }`}
                    >
                      LMS
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <li
              className={`nav-item insights relative ${(isActiveRoute("/insights/tournaments") ||
               isActiveRoute("/insights/success-stories") ||
                isActiveRoute("/insights/webinars") ||
                isActiveRoute("/insights/brochures") ||
                isActiveRoute("/insights/white-papers") ||
                // isActiveRoute("/insights/news-announcements") ||
                isActiveRoute("/insights/csr")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleInsights}
              onMouseLeave={toggleInsights}
            >
              <span className="nav-item-link">
                Insights {isInsightsOpen ? "-" : "+"}
              </span>
              {isInsightsOpen && (
                <ul className="submenu  shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2  text-white fade-in-up z-50">
                  <Link to="/insights/tournaments">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/tournaments") &&
                        "active-submenu"
                        }`}
                    >
                      Tournaments
                    </li>
                  </Link>
                  <Link to="/insights/success-stories">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/success-stories") &&
                        "active-submenu"
                        }`}
                    >
                      Success Stories
                    </li>
                  </Link>
                  <Link to="/insights/webinars">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/webinars") && "active-submenu"
                        }`}
                    >
                      Webinars
                    </li>
                  </Link>
                  <Link to="/insights/brochures">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/brochures") && "active-submenu"
                        }`}
                    >
                      Brochures
                    </li>
                  </Link>
                  <Link to="/insights/white-papers">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/white-papers") &&
                        "active-submenu"
                        }`}
                    >
                      White Papers
                    </li>
                  </Link>
                  {/* <Link to="/insights/news-announcements">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/news-announcements") &&
                        "active-submenu"
                        }`}
                    >
                      News & Announcements
                    </li>
                  </Link> */}
                  <Link to="/insights/csr">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/csr") && "active-submenu"
                        }`}
                    >
                      CSR
                    </li>
                  </Link>
                  <Link to="/insights/expectationOfHiring">
                    <li
                      className={`submenu-item ${isActiveRoute("/insights/expectationOfHiring") && "active-submenu"
                        }`}
                    >
                      Hiring expectations
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <li
              className={`nav-item offerings relative ${(isActiveRoute("/offerings/thinquotient-franchise") ||
                isActiveRoute("/offerings/channel-partner")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleOfferings}
              onMouseLeave={toggleOfferings}
            >
              <span className="nav-item-link">
                Offerings {isOfferingsOpen ? "-" : "+"}
              </span>
              {isOfferingsOpen && (
                <ul className="submenu  shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2  text-white fade-in-up z-50">
                  <Link to="/offerings/thinquotient-franchise">
                    <li
                      className={`submenu-item ${isActiveRoute("/offerings/thinquotient-franchise") &&
                        "active-submenu"
                        }`}
                    >
                      ThinkQuotient Franchise
                    </li>
                  </Link>
                  <Link to="/offerings/channel-partner">
                    <li
                      className={`submenu-item ${isActiveRoute("/offerings/channel-partner") &&
                        "active-submenu"
                        }`}
                    >
                      Channel Partner
                    </li>
                  </Link>
                </ul>
              )}
            </li>

            <li
              className={`nav-item careers relative ${(isActiveRoute("/careers") ||
                isActiveRoute("/careers/business-development")) &&
                "active-nav"
                }`}
              onMouseEnter={toggleCareers}
              onMouseLeave={toggleCareers}
            >
              <span className="nav-item-link">
                Careers {isCareersOpen ? "-" : "+"}
              </span>
              {isCareersOpen && (
                <ul className="submenu  shadow-lg top-24 primary-bg-color ps-6 pe-8 pt-4 pb-2  text-white fade-in-up z-50">
                  <Link to="/careers/ITFreshers">
                    <li
                      className={`submenu-item ${isActiveRoute("/careers/ITFreshers") &&
                        "active-submenu"
                        }`}
                    >
                      IT Fresher
                    </li>
                  </Link>
                  <Link to="/careers/work-with-us">
                    <li
                      className={`submenu-item ${isActiveRoute("/careers/work-with-us") &&
                        "active-submenu"
                        }`}
                    >
                      Work With Us
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <Link to="/contact">
              <li
                className={`nav-item ${isActiveRoute("/contact") && "active-nav"
                  }`}
              >
                Contact Us
              </li>
            </Link>


            <ul className="nav-item services flex">

              <span className="mr-4">
                <a href="https://www.instagram.com/thinkquotient_software/" target="_blank">
                  <FaInstagram style={{ fontSize: '1.8em' }} className="skyblue-image" />
                </a>
              </span>
              <span className="mr-4">
                <a href="https://www.linkedin.com/company/thinkquotient?originalSubdomain=in" target="_blank">
                  <FaLinkedinIn style={{ fontSize: '1.8em' }} className="skyblue-image" />
                </a>
              </span>
              <span className="mr-4">
                <a href=" https://www.youtube.com/@thinkquotient1429" target="_blank">
                  <FaYoutube style={{ fontSize: '1.9em' }} className="skyblue-image" />
                </a>
              </span>
              <span className="mr-4">
                <a href=" https://www.facebook.com/ThinkQuotient" target="_blank">
                  <FaFacebookF style={{ fontSize: '1.6em' }} className="skyblue-image" />
                </a>
              </span>


            </ul>




          </ul>
        </nav>
      </div>
      {/* Large Screen Navbar Ends */}
    </div>
  );
};

export default Header;
