import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

const LMS = () => {
  useEffect(() => {
    document.title = "LMS - Think Quotient";
    AOS.init();
  }, []);

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>
        {/* Page Title */}
        {/* <title>{pageTitle}</title> */}
        <meta name="title" content={metaTitle} />

        {/* Remaining Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Facebook Pixel Code */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '473030589157148');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>{`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=473030589157148&ev=PageView&noscript=1"/>
        `}</noscript>

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []; 
          function gtag() { 
            dataLayer.push(arguments); 
          } 
          gtag("js", new Date()); 
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>

      </Helmet>


      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div className="flex">
          <div className="lms-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-3xl font-semibold mb-4 ">
                  Welcome to Our Learning Management System (LMS)
                  <p className="md:text-xl mb-8">
                    Empowering Education Every Step of the Way
                  </p>
                </h1>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center py-14 mb-10  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
                {" "}
                At Our Company, we understand the transformative power of
                education.
              </p>
            </div>
            <br /> That's why we've developed our cutting-edge Learning Management
            System (LMS), designed to enhance the learning experience, streamline
            administrative tasks, and provide valuable insights into students'
            progress. With our LMS, education becomes a seamless journey from the
            moment a student joins until they embark on their professional
            careers.
          </h1>
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="m-0 md:flex  service-boxes container mx-auto">
          <div className="md:w-2/3 md:py-20 md:pe-20 flex flex-col justify-center mx-4 md:mx-0"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h1 className="text-2xl font-bold">What Our LMS Can Do for You:</h1>
            <p className="mt-6  list-items ps-6 mb-2 font-semibold">
              Comprehensive Student Tracking
            </p>
            <p className="ps-7">
              With our LMS, students can effortlessly track all their educational
              activities. They gain full visibility into their progress, ensuring
              they stay on the right path to success.
            </p>
            <p className="mt-6  list-items ps-6 mb-2 font-semibold">
              Effortless Batch Management
            </p>
            <p className="ps-7">
              Our system simplifies batch management. Create student batches and
              assign dedicated teachers to each batch, ensuring personalized
              guidance and support.
            </p>
            <p className="mt-6  list-items ps-6 mb-2 font-semibold">
              Teacher Empowerment
            </p>
            <p className="ps-7">
              Teachers play a pivotal role in a student's journey, and our LMS
              equips them with powerful tools:
              <ul className="mt-4 space-y-2">
                <li>
                  <span className="font-semibold">Topic Feedback:</span> Teachers
                  can provide constructive feedback on every topic within any
                  subject, helping students improve continually.
                </li>
                <li>
                  <span className="font-semibold">Attendance Recording:</span>{" "}
                  Keep precise records of student attendance, ensuring
                  accountability and helping students stay engaged.
                </li>
                <li>
                  <span className="font-semibold">
                    Test Scores and Performance Tracking:
                  </span>{" "}
                  Monitor students' test scores and performance over time,
                  identifying areas that need improvement and recognizing
                  achievements.
                </li>
              </ul>
            </p>
          </div>
          <div className="md:w-1/2 mt-6 md:mt-0"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/lms-2.jpg"
              className="object-cover object-right h-full w-full"
            />
          </div>
        </div>

        {/* Second */}
        <div className="m-0 md:flex service-boxes container mx-auto py-10">
          <div className="w-1/3 hidden md:block"
            data-aos="fade-right"
            data-aos-duration="1000">
            <img
              src="/assets/images/journey-m.jpg"
              className="object-cover object-left h-full w-full"
            />
          </div>
          <div className="md:w-2/3  p-10 md:p-20 flex flex-col justify-center"
            data-aos="fade-left"
            data-aos-duration="1000">
            <h1 className="text-2xl font-bold">Journey Mapping:</h1>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-4 ">
              Our LMS is not just about individual pieces of data; it's about the
              complete educational journey.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
              {" "}
              From the moment a student joins, you can trace their progress
              through every stage of their academic path, from learning to
              placement in a company.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-4 font-semibold">
              Certificate Creation
            </p>
            <p className="ps-7">
              Empower your teachers to recognize students' accomplishments with
              our certification tool.{" "}
            </p>
            <p className="ps-7">
              Teachers can easily create certificates, celebrating the milestones
              achieved by their students.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-4 font-semibold">
              Student Access
            </p>
            <p className="ps-7">
              We believe in transparency and empower students with their own login
              access.
            </p>
            <p className="ps-7">
              This allows students to review their journey, track their
              achievements, and stay motivated throughout their educational
              adventure.
            </p>
          </div>
          <div className=" md:hidden block">
            <img
              src="/assets/images/journey-m.jpg"
              className="object-cover object-left h-full w-full"
            />
          </div>
        </div>

        {/* Third */}
        <div className="m-0 md:flex  service-boxes container mx-auto py-10">
          <div className="md:w-2/3 md:py-20 md:pe-20 px-10 md:px-0 flex flex-col justify-center"
            data-aos="fade-right"
            data-aos-duration="1000">
            <h1 className="text-2xl font-bold">
              Join the Revolution in Education
            </h1>
            <p className="mt-6  list-items ps-6 mb-2">
              Our Learning Management System is more than just a tool; it's a
              catalyst for educational excellence.
            </p>
            <p className="mt-6  list-items ps-6 mb-2">
              Whether you're an institution dedicated to nurturing young minds or
              a student on a quest for knowledge, our LMS is here to support your
              journey. Together, we can revolutionize the way we learn, teach, and
              succeed.
            </p>
          </div>
          <div className="md:w-1/3 mt-6 md:mt-0"
            data-aos="fade-right"
            data-aos-duration="1000">
            <img
              src="/assets/images/reval.jpg"
              className="object-cover  h-full w-full"
            />
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default LMS;
