import React, { useEffect } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SuccessStoryCarousel from "../../Components/Home/SuccessStoryCarousel";

const SuccessStories = () => {
  useEffect(() => {
    document.title = "Success Stories - Think Quotient";
    AOS.init();

  }, []);
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  //slide
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    prevArrow: (
      <button type="button" className="slick-arrow slick-prev">
        Left &lt;
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-arrow slick-next">
        Right &gt;
      </button>
    ),
  };

  return (

    <>
      <Helmet>
        {/* Page Title */}
        {/* <title>{pageTitle}</title> */}
        <meta name="title" content={metaTitle} />

        {/* Remaining Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Facebook Pixel Code */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '473030589157148');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>{`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=473030589157148&ev=PageView&noscript=1"/>
        `}</noscript>

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []; 
          function gtag() { 
            dataLayer.push(arguments); 
          } 
          gtag("js", new Date()); 
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>

      </Helmet>


      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="succes-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-3xl md:text-5xl font-semibold mb-4 ">
                  Success Stories
                </h1>

                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
                <div className='container mx-auto md:py-20 py-10'>
                    <div className="text-black text-2xl md:text-5xl font-bold text-center md:w-1/2 mx-auto">Be a Part of ThinkQuotient  <br />

                        <span className='about-us-banner-txt'>Franchise Network </span></div>
                </div>
            </div> */}

        <div className="flex flex-col items-center py-14   bg-gray-100 fade-in-down px-6">
          <h1 className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000">
            <div>
              <p className="font-bold md:text-2xl text-xl">
                Inspiring Journeys, Empowering Futures
              </p>
            </div>
            <br /> Discover stories of perseverance, passion, and achievement that
            highlight the impact of ThinkQuotient's commitment to fostering a
            culture of continuous growth and success. Be inspired by real-world
            examples of individuals who have unlocked their full potential,
            embraced new opportunities, and paved the way for a brighter future.
            Join us in celebrating these remarkable success stories, and let them
            motivate you on your own path to excellence. Your success story could
            be the next chapter we proudly share.
          </h1>
        </div>

        <div>
          <div className="container mx-auto py-16">
            <div className="text-black md:text-3xl text-xl font-bold text-center">
              Awards Won by some of our Resources
            </div>
          </div>
        </div>

        {/* First */}
        <div className="m-0 md:flex  service-boxes">
          <div className="md:w-1/2  md:p-20 p-10 flex flex-col justify-center"
            data-aos="fade-right"
            data-aos-duration="1000">
            {/* <h1 className="text-2xl font-bold">Channel Partner Vision</h1> */}
            {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}
            <div className="flex items-center space-x-4">
              <img
                src="/assets/images/awards.svg"
                alt="Image 2"
                className="w-1/6 object-cover rounded-md "
              />
              <div>
                <p className="mt-4  list-items ps-4">
                  <span className="font-bold">
                    Mayur Munde - Excellence Award – Zensar Annual Awards
                  </span>
                </p>
                <p className="mt-6  list-items ps-4 ">
                  <span className="font-bold">
                    Vinas Dharsandiya – Excellence Award – Zensar Annual Awards
                  </span>
                </p>
                <p className="mt-6  list-items ps-4 ">
                  <span className="font-bold">
                    Amruta Mulik – Rising Star – Zensar Quarterly Awards
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="md:w-2/5 p-8 flex justify-center items-center  space-x-8"
            data-aos="fade-left"
            data-aos-duration="1000">
            <div className="space-y-8">
              <img
                src="/assets/images/ss1.jpg"
                alt="Image 1"
                className="object-cover rounded-md mr-4 border shadow-md"
              />
              <img
                src="/assets/images/ss3.png"
                alt="Image 1"
                className=" object-cover rounded-md mr-4 border shadow-md"
              />
            </div>
            <img
              src="/assets/images/ss2.png"
              alt="Image 2"
              className="w-1/2 object-cover rounded-md mt-28 border shadow-md"
            />
          </div>
        </div>

        <div className="bg-gray-100 pb-10">
          <div className="container mx-auto ">
            <div>
              <div className="mx-auto  md:pt-20">
                <div className="text-black md:text-3xl text-xl font-bold text-left p-5 md:p-0">
                  TATA Success Story
                </div>
              </div>
            </div>

            {/* First */}
            <div className=" m-0 md:flex  service-boxes">
              <div className="md:w-9/12   p-10 md:p-0 flex flex-col justify-center"
                data-aos="fade-right"
                data-aos-duration="1000">
                {/* <h1 className="text-2xl font-bold">Channel Partner Vision</h1> */}
                {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}
                <div className="flex items-center">
                  <img
                    src="/assets/images/tss.jpg"
                    alt="Image 2"
                    className="object-cover rounded-md border shadow-md w-11/12"
                  />
                </div>
              </div>
              {/* Right Section */}
              <div className="md:w-3/12 p-8 flex justify-center items-center "
                data-aos="fade-left"
                data-aos-duration="1000">
                <div className="space-y-8">
                  <img
                    src="/assets/images/tss2.jpg"
                    alt="Image 1"
                    className="object-cover rounded-md mr-4 border shadow-md"
                  />
                  <img
                    src="/assets/images/tss3.png"
                    alt="Image 1"
                    className=" object-cover rounded-md mr-4 border shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <SuccessStoryCarousel />

        <TestimonialsandBlogSec />

        <ClientLogoCarousel />

        <Footer />
      </div>
    </>
  );
};

export default SuccessStories;
