import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
// import Pattern from "./Pattern";
import AOS from "aos";
import "aos/dist/aos.css";
import ApiPath from "../../Components/Common/Apiurl";
import { CountryContext } from "../../CountryContext";




const ExperienceTrainerBannerSection = () => {
  // Custom Arrow Components

  const [formVisible, setFormVisible] = useState(true);
  const country = useContext(CountryContext);
  const websiteURL = window.location.origin;

  const [formError, setFormError] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    country:'',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const CustomPrevArrow = (props) => (
    <button
      {...props}
      className="absolute top-3/4 2xl:left-36  left-10 transform "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 text-white  mt-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      {...props}
      className="absolute top-3/4 2xl:left-44 left-20 transform "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 text-white mt-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    // autoplay: true,
    // autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
  };

  const [selectedCountry, setSelectedCountry] = useState("India"); 
    const countryOptions = ["Australia", "Germany", "India", "New Zealand", "United States", "Other"];

  useEffect(() => {
   
        if (countryOptions.includes(country)) {
          setSelectedCountry(country);
        } else {
          setSelectedCountry("India");
        }
  }, [country]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setErrors({ ...errors, [name]: '' });
    
    if (name === "country") {
      setSelectedCountry(e.target.value);
    }
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [rawPhoneNumber, setRawPhoneNumber] = useState("");
  
  const handleChangephone= (e) => {
    const { name, value } = e.target;
    const cleanedPhoneNumber = value.replace(/[^\d]/g, "");
    if (country === "India") {
      // Store phone number without formatting for India
      setRawPhoneNumber(cleanedPhoneNumber);
      setPhoneNumber(cleanedPhoneNumber);  // plain 10-digit number for India
    } else {
      // For other countries, format the phone number
      setRawPhoneNumber(cleanedPhoneNumber);
      setPhoneNumber(formatPhoneNumber(cleanedPhoneNumber));  // formatted phone number for other countries
    }
    setErrors({ ...errors, [name]: '' });
  };

    const formatPhoneNumber = (value) => {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, "");
      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };
  
    const handleBlur = () => {
      // Format phone number when input loses focus
      setPhoneNumber(formatPhoneNumber(rawPhoneNumber));
      console.log(phoneNumber);
    };


    
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      const cleanedPhoneNumber = rawPhoneNumber.replace(/[^\d]/g, ""); 
  
    const formPayload = {
     
      
      name: e.target.firstname.value,
      email: e.target.email.value.trim(),
      phone: cleanedPhoneNumber,
    //  country: e.target.country.value,
    message: e.target.message.value.trim()+ ' IP: ' + country + " dropdown : " + e.target.country.value  +  " | URL: " + websiteURL,
    lookingFor: e.target.subject.value,
      queryPageIndex: "Experience_Training",
    };
  
    let formIsValid = true;
    const newErrors = {
      name: '',
      email: '',
      phone: '',
      country:'',
      message: '',
      subject: ''
    };
  
    // Validate 'name' field
    if (!formPayload.name.trim()) {
      newErrors.name = 'Name is required';
      formIsValid = false;
    }
  
    // Validate 'email' field
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!formPayload.email.trim()) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if(!emailRegex.test(formPayload.email.trim())) {
      newErrors.email = 'Invalid email format';
      formIsValid = false;
    }
  
    // Validate 'phone' field
    const phoneValue = formPayload.phone;


    const phoneRegex = /^\d{10}$/; // 10 digits for India
    const phoneRegexUSA = /^(?:\+1\s?)?\(?\d{3}\)?\s?\d{3}[-\s]?\d{4}$/; // Regex for USA (including optional country code)
    
    if (country === "India") {
      // If country is India, validate using the India-specific regex
      if (phoneValue && !phoneRegex.test(phoneValue)) {
        newErrors.phone = 'Phone number must be exactly 10 digits';
        formIsValid = false;
      }
    } else  {
    
      if (!rawPhoneNumber || !phoneRegexUSA.test(phoneNumber)){
        newErrors.phone = 'Phone number must follow the USA format (e.g., (123) 456-7890)';
        formIsValid = false;
      }
    }

// if (!phoneValue || phoneValue.length === 0) {
//   newErrors.phone = 'Phone Number is required';
//   formIsValid = false;
// } else 
// if ( phoneValue!="" && !phoneRegex.test(phoneValue)) {
//   newErrors.phone = 'Phone is must be number';
//   formIsValid = false;
// }

  
    // Validate 'message' field
    // if (!formPayload.message.trim()) {
    //   newErrors.message = 'Message is required';
    //   formIsValid = false;
    // }
  
    if (!formPayload.lookingFor) {
      newErrors.subject = 'Please select category';
      formIsValid = false;
    }

    // if (!formPayload.country) {
    //   newErrors.country = 'Please select a country';
    //   formIsValid = false;
    // }
  
    if (!selectedCountry) {
      newErrors.country = '';
      formIsValid = false;
    }

    setErrors(newErrors);
  
    if (!formIsValid) {
      return;
    }
  
    try {
      setLoading(true);
      const response = await fetch(
        ApiPath+"/tqenquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formPayload),
        }
      );
  
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
  
        setFormVisible(false);
        setPhoneNumber("");
        setRawPhoneNumber("");
  
        setTimeout(() => {
          setFormVisible(true);
          setLoading(false);
        }, 3000);
      } else {
        console.error("Error submitting the form", response.statusText);
        setFormError('An error occurred while submitting the form. Please try again later.');
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting the form", error);
      setFormError('An error occurred while submitting the form. Please try again later.');
      setLoading(false);
    }
  };
  






  return (
    <>
      {/* <Pattern /> */}

      <div className="relative corporatetraining-banner-sec">
        <Slider {...sliderSettings}>
          {/* Slide 1 */}
          <div className="slide flex 2xl:px-36 px-6 md:px-20 xl:px-20 !z-[2]">
            <div className="container text-white  md:w-2/4 xl:w-3/5 ">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold mb-4 mt-10 md:mt-24 ">
              Unlock Your Future with Full-Stack, Data Science, GenAI and KNIME Training
              </h1>
              <p className="md:text-xl mb-8 z-10">
              Gain cutting-edge skills in Full-Stack Development, Generative AI, KNIME, and Data Science through hands-on, industry-relevant training.              
              </p>
              {/* <Link to="/contact">
                <button className="btn-style-2 px-6 py-2 ">
                  Get In Touch With Us
                </button>
              </Link> */}
              {/* Arrows */}
              {/* <CustomPrevArrow />
              <CustomNextArrow /> */}
            </div>
          </div>

          {/* Slide 2 */}
          {/* <div className="slide flex 2xl:px-36 px-10 ">
            <div className="container md:w-2/4">
              <h1 className="md:text-2xl font-bold mb-4 ">
                Interested In Introducing Digital Transformation To Your
                Business?
              </h1>
              <p className="md:text-lg mb-8">
                With expertise in cutting-edge technologies and custom software
                development methodologies, Think Quotient has all the tools to
                accelerate your digital and technology transformation.
                #ThinkDigital
              </p>
            
              <CustomPrevArrow />
              <CustomNextArrow />
            </div>
          </div> */}

          {/* Slide 3 */}
          {/* <div className="slide flex 2xl:px-36 px-10">
            <div className="container md:w-2/4">
              <h1 className="md:text-2xl font-bold mb-4 ">
                Interested In Introducing Digital Transformation To Your
                Business?
              </h1>
              <p className="md:text-lg mb-8">
                With expertise in cutting-edge technologies and custom software
                development methodologies, Think Quotients has all the tools to
                accelerate your digital and technology transformation.
                #ThinkDigital
              </p>
             
              <CustomPrevArrow />
              <CustomNextArrow />
            </div>
          </div> */}
        </Slider>

        {/* Contact Form Section */}
        {formVisible && (
          <div className="absolute top-6 lg:top-10 xl:top-2 lg:right-12 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right hidden md:block">
            <div className="px-0 xl:px-3">
              <h2 className="text-2xl font-semibold mb-4">
                Request a Callback
              </h2>
              <form className="banner-contact-form" onSubmit={handleSubmit}>
                {/* Add your form fields here */}
                <div className="mb-1">
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="Name *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>
                <div className="mb-1">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <p className="text-red-500">{errors.email}</p>}
                </div>
                <div className="mb-4">
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    className="w-full rounded-md p-2"
                    value={phoneNumber}
                    
                    onBlur={country === "India" ? null :handleBlur}
                    onChange={handleChangephone}
                    maxLength={country === "India" ? 10 : 14}
                    // required
                  />
                  {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                </div>

                <div className="mb-4">
                  <select
                    id="subject"
                    name="subject"
                    className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                    onChange={handleChange}
                    required
                  >
                    <option value="" label="Select Category" />
                    <option value="IT Service & Development">
                      IT Service & Development
                    </option>
                    <option value="Fullstack Training">
                    Fullstack Training
                    </option>
                    <option value="Corporate Training">Corporate Training</option>
                    <option value="Corporate Collaborations">Corporate Collaborations</option>
                    <option value="Generative AI & KNIME">Generative AI & KNIME</option>
                    <option value="Channel Partner">Channel Partner</option>
                  </select>
                  {errors.subject && <p className="text-red-500">{errors.subject}</p>}

                </div>

                <div className="mb-4">
                  <select
                    id="country"
                    name="country"
                    className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                    onChange={handleChange}
                    value={selectedCountry}
                    required
                  >
                    {/* <option value="" label="Select Country" />
                    <option value="India">India</option>
                    <option value="United States">United States</option>
                    <option value="Germany">Germany</option>
                    <option value="Australia">Australia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Other">Other</option> */}
                     {/* <option value="" label="Select Country" /> */}
                    {countryOptions.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors.country && <p className="text-red-500">{errors.country}</p>}
                </div>

                <div className="mb-2">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Write a message "
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    // required
                  />
                  {/* {errors.message && <p className="text-red-500">{errors.message}</p>} */}
                </div>
                {/* Add more fields as needed */}
                <button type="submit" className="btn-style-1 w-full py-2 my-4" disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
                {formError && <p className="text-red-500">{formError}</p>}
              </form>
            </div>
          </div>
        )}
        {!formVisible && (
          <div className="absolute top-40 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right hidden md:block">
            <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
            <p className="text-lg mb-4">
              Thank you for contacting us. We'll get back to you soon.
            </p>
          </div>
        )}
      </div>
      {/* Thank You Message Section */}

      {/* Contact Form Section */}
      <div className=" top-2 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right md:hidden">
        {/* Contact Form Section */}
        {formVisible && (
          <div className=" 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right ">
            <div>
              <h2 className="text-2xl font-semibold mb-4">
                Request a Callback
              </h2>
              <form className="banner-contact-form" onSubmit={handleSubmit}>
                {/* Add your form fields here */}
                <div className="mb-3">
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="Name *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>
                <div className="mb-2">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <p className="text-red-500">{errors.email}</p>}
                </div>
                <div className="mb-5">
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    className="w-full rounded-md p-2"
                    value={phoneNumber}
                    
                    onBlur={country === "India" ? null :handleBlur}
                    onChange={handleChangephone}
                    maxLength={country === "India" ? 10 : 14}
                    // required
                  />
                  {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                </div>

                <div className="mb-2">
                  <select
                    id="subject"
                    name="subject"
                    className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                    onChange={handleChange}
                    required
                  >
                    <option value="" label="Select Category" />
                    <option value="IT Service & Development">
                      IT Service & Development
                    </option>
                    <option value="Fullstack Training">
                    Fullstack Training
                    </option>
                    <option value="Corporate Training">Corporate Training</option>
                    <option value="Corporate Collaborations">Corporate Collaborations</option>
                    <option value="Generative AI & KNIME">Generative AI & KNIME</option>
                    <option value="Channel Partner">Channel Partner</option>
                  </select>
                  {errors.subject && <p className="text-red-500">{errors.subject}</p>}

                </div>

                <div className="mb-4">
                  <select
                    id="country"
                    name="country"
                    className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                    onChange={handleChange}
                    value={selectedCountry}
                    required
                  >
                    {/* <option value="" label="Select Country" />
                    <option value="India">India</option>
                    <option value="United States">United States</option>
                    <option value="Germany">Germany</option>
                    <option value="Australia">Australia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Other">Other</option> */}
                     {/* <option value="" label="Select Country" /> */}
                    {countryOptions.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors.country && <p className="text-red-500">{errors.country}</p>}
                </div>

                <div className="mb-3">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Write a message "
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {/* {errors.message && <p className="text-red-500">{errors.message}</p>} */}
                </div>
                {/* Add more fields as needed */}
                <button type="submit" className="btn-style-1 w-3/4 sm:w-full py-2 my-4" disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
                {formError && <p className="text-red-500">{formError}</p>}
              </form>
            </div>
          </div>
        )}
        {!formVisible && (
          <div className=" 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right md:hidden">
            <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
            <p className="text-lg mb-4">
              Thank you for contacting us. We'll get back to you soon.
            </p>
          </div>
        )}
      </div>

     

    </>
  );
};

export default ExperienceTrainerBannerSection;
