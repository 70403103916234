import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import WhyUs from "../../Components/Home/WhyUs";
import CorporateBannerSection from "./CorporateBannerSection"
import { motion } from "framer-motion";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Clientele from "./Clientele";
import CorporateTestimonials from "./CorporateTestimonials";
import StickyElements from "./StickyElements";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const CorporateTraining = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);
  const [screenSize, setScreenSize] = useState(null);

  useEffect(() => {
    const handleClickOutside = () => setShowTooltip(false);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };
  useEffect(() => {
    document.title = "Corporate Training - Think Quotient";
    AOS.init();

    // // Check if the URL has the anchor link, and scroll to the section if present
    // const hash = window.location.hash.substring(1);
    // if (hash === "web-development") {
    //   scrollToSection(webDevelopmentRef);
    // } else if (hash === "software-development") {
    //   scrollToSection(softwareDevelopmentRef);
    // } else if (hash === "app-development") {
    //   scrollToSection(appDevelopmentRef);
    // }
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");
      console.log("hello " + scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");

      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [activeTab, setActiveTab] = React.useState("tab1");
  const [tabIndex, setTabIndex] = React.useState(0);
  const data = [
    {
      count: "01",
      label: "Experienced Trainers",
      value: "tab1",
      image: "/assets/images/Experienced Trainer.jpg",
      desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },

    {
      count: "02",
      label: "Customized Syllabus",
      value: "tab2",
      image: "/assets/images/programming-background-collage.jpg",
      desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
    },
    {
      count: "03",
      label: "Improved Employee Performance",
      image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab3",
      desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
    {
      count: "04",
      label: "Effective Tracking",
      image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
      value: "tab4",
      desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
    },
    {
      count: "05",
      label: "Capstone Project",
      value: "tab5",
      image: "/assets/images/Capstone Project.jpg",
      desc: `Foster practical learning through hands-on projects that replicate real-world scenarios. Employees apply their skills to solve problems, building confidence and competence.`,
    },
    {
      count: "06",
      label: "Affordable Rates",
      value: "tab5",
      image: "/assets/images/Affordable Fees.jpg",
      desc: `Access high-quality corporate training programs at competitive prices. We ensure your investment delivers exceptional value without exceeding your budget.`,
    },
  ];

  const metaTitle = "Corporate Training - Think Quotient";
  const helmetContent = getHelmetContent();

  const fadeVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };


  const [activeTestimonial, setActiveTestimonial] = useState(0); // Track active testimonial slide index

  const testimonialsRef = useRef(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 3500,
    beforeChange: (_, next) => setActiveTestimonial(next), // Update active testimonial on change
  };

  const goToTestimonialSlide = (index) => {
    testimonialsRef.current.slickGoTo(index);
  };


  const scrollRef = useRef(null); // Reference to the content container


  const handleTabClick = (index) => {
    setTabIndex(index);
    // Scroll to the container
    if (screenSize === 'mobile' && scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const [isTabVisible, setIsTabVisible] = useState(false);
  const tabRef = useRef(null); // Create a ref for the tab content

  // Use IntersectionObserver to track tab visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("Observer Entry:", entry);
        // Set visibility state based on tab visibility
        setIsTabVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% of the tab needs to be visible to consider it visible
    );

    if (tabRef.current) {
      observer.observe(tabRef.current); // Start observing the tab content
    }

    // Cleanup observer when component unmounts or tabRef changes
    return () => {
      if (tabRef.current) {
        observer.unobserve(tabRef.current);
      }
    };
  }, [tabRef]);


  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1024) {
      setScreenSize('desktop'); // Desktop screen
    } else if (width >= 768 && width <= 1024) {
      setScreenSize('tablet'); // Tablet screen
    } else {
      setScreenSize('mobile'); // Mobile screen
    }
    console.log("Window width:", width); // Debugging line

  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Update on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener
    };
  }, []);

  // Log screenSize whenever it changes
  useEffect(() => {
    console.log("Updated screenSize:", screenSize);
  }, [screenSize]);

  // Set the timer only on large screens
  useEffect(() => {

    console.log("Current screenSize:", screenSize);
    console.log("Is Tab Visible:", isTabVisible);
    if ((screenSize === 'desktop' || screenSize === 'tablet') && isTabVisible) {
      const interval = setInterval(() => {
        console.log("Changing tab index...");
        setTabIndex((prevIndex) => (prevIndex + 1) % data.length); // Change to the next tab
      }, 5000); // Change tab every 5 seconds

      return () => clearInterval(interval); // Cleanup interval on tab change
    }

  }, [screenSize, isTabVisible, data.length]);

  return (

    <>
      <Helmet>
        {/* Page Title */}
        <title>{"Corporate Training - Think Quotient"}</title>
        <meta
          name="description"
          content={
            "Empower your team with Think Quotient's Corporate Training Program. Customized courses in web development, software development, app development, and cutting-edge technologies to boost productivity and skills.,corporate training, IT training for companies, custom training programs, upskilling teams, web development training, software development, app development, artificial intelligence, machine learning, data science, python, full stack development, think quotient"
          }
        />
        <meta
          name="keywords"
          content={
            "corporate training, IT training for companies, custom training programs, upskilling teams, web development training, software development, app development, artificial intelligence, machine learning, data science, python, full stack development, think quotient"
          }
        />

        {/* Dynamic Meta Tags */}
        {helmetContent?.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Social Media Sharing */}
        <meta property="og:title" content={metaTitle || "Corporate Training - Think Quotient"} />
        <meta
          property="og:description"
          content="Think Quotient's Corporate Training Program helps companies upskill their teams in web, software, and app development, ensuring they stay ahead in the tech-driven world."
        />
        <meta
          property="og:image"
          content="https://www.thinkquotient.com/assets/images/corporate-training-banner.png"
        />
        <meta property="og:url" content="https://www.thinkquotient.com/training/corporatetraining" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle || "Corporate Training - Think Quotient"} />
        <meta
          name="twitter:description"
          content="Upskill your workforce with Think Quotient's Corporate Training. Tailored programs in IT, including web, software, and app development."
        />
        <meta
          name="twitter:image"
          content="https://www.thinkquotient.com/assets/images/corporate-training-banner.png"
        />

        {/* Robots and Author */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Think Quotient" />

        {/* Facebook Pixel Code */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '473030589157148');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>{`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=473030589157148&ev=PageView&noscript=1"/>
        `}</noscript>

        {/* Updated Google Tag Manager */}
        <script>
          {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KTNBVH9V');
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-143016865-1");
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-BSFPPN5E9D');
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-574047969');
    `}
        </script>

        {/* Google Tag Manager Noscript */}
        <noscript>
          {`
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
    `}
        </noscript>
      </Helmet>




      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />
        <CorporateBannerSection />

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
                Empowering Growth and Innovation at ThinkQuotient{" "}
              </p>
            </div>
            <br /> At ThinkQuotient Software, we are committed to empowering organizations and their teams. Whether you're looking to upskill your workforce or equip them with the latest IT technologies, our corporate training programs are tailored to drive growth and innovation. The perfect partner for organizations to stay ahead in the dynamic IT landscape.
          </h1>
        </div>

        <div className="flex flex-col items-center py-14  fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
                Our Technologies & Training Approach{" "}
              </p>
            </div>
          </h1>
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="m-0 flex flex-col lg:flex-row gap-x-8 justify-between max-w-s sm:p-2 sm:mx-0 mx-0 ">
          <div
            className="lg:w-1/2 md:p-10 p-10 flex flex-col justify-center "
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h2 className="text-2xl font-semibold text-center mb-6">
              Technologies
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 gap-y-8 ">
              {/* Image 1 */}
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-lg transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/java.svg"
                    alt="Java"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Java</p>
              </div>

              {/* Image 2 */}
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/dotnet.1024x1024 (1).png"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">DotNet</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/c.svg"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">C++</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/spring.svg"
                    alt="SpringBoot"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">SpringBoot</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/hibernate-icon.svg"
                    alt="Hibernate"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Hibernate</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/sql.svg"
                    alt="SQL"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">SQL</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/angular.svg"
                    alt="Angular"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Angular</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/react.svg"
                    alt="React"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">React</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/KnimeImg.png"
                    alt="KNIME"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">KNIME</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/python.svg"
                    alt="Python"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Python</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/data-science.svg"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Data Science</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/artificial-intelligence.png"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">GenAI</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/Machine Learning.png"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Machine Learning</p>
              </div>
              <div className="flex flex-col items-center relative group"
                onClick={(e) => e.stopPropagation()}>
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110"
                  onClick={() => setShowTooltip(!showTooltip)}>
                  <img
                    src="/assets/images/brackets.png"
                    alt="Coding"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain p-2"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Your Choice Our Expertise</p>
                {/* Tooltip */}

                <div
                  className={`absolute bottom-full mb-2 flex flex-col items-center ${showTooltip ? 'flex' : 'hidden'
                    } group-hover:flex sm:hidden`} // Shows on hover for large screens and toggle visibility for small screens
                >
                  <div className="bg-gray-700 text-white text-sm sm:text-base rounded py-1 px-2"
                    style={{ backgroundColor: '#01a8ef' }}>

                    Offers training in diverse technologies like EJB, Knockout.js, QA, SharePoint, Oracle, Flutter, and more.
                  </div>
                  <div className="w-3 h-3 bg-gray-700 rotate-45 transform"></div>
                </div>
              </div>

            </div>


          </div>
          <div
            className="lg:w-1/2  flex flex-col"
            data-aos="fade-left"
            data-aos-duration="1000"
            id="web-development"
          >
            {/* Top Section: Image */}
            <div className="flex-1 ">
              <img
                src="/assets/images/training approach.png"
                className="object-contain w-full h-full"
                alt="Training Approach"
              />
            </div>

            {/* Bottom Section: Text */}
            <div className="flex-1   flex items-center justify-center text-justify" data-aos="fade-up" data-aos-duration="1500">
              <div className="bg-gradient-to-r from-blue-100 via-white to-blue-100 p-6 rounded-lg shadow-lg shadow-md w-full max-w-s sm:p-2 sm:mx-0 mx-0 md:mx-4 lg:mx-6 mt-4 ">
                <p className="text-gray-800 text-lg sm:text-xl md:text-xl font-medium leading-relaxed text-justify p-4 " style={{ wordSpacing: '0.07em' }}>
                  Our comprehensive training approach ensures that your team acquires practical skills and knowledge. We focus on industry-specific needs, providing personalized learning experiences tailored to your organization's goals. Our expert trainers deliver engaging sessions, combining theoretical concepts with hands-on exercises. Regular assessments and feedback mechanisms help us monitor progress and identify areas for improvement.    </p>
              </div>
            </div>

          </div>

        </div>

        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                Benefits to your Organization{" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
          ref={tabRef} >
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex(index)} // Update tabIndex on click
                  className={`${tabIndex === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div className="tab-content md:w-1/2 p-4" id="slide-sec">
              {data[tabIndex] && (
                <motion.div
                  key={tabIndex} // Re-run animation on tabIndex change
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeVariants}
                  className="relative w-full"
                >
                  <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                    <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                      <motion.img
                        src={data[tabIndex].image}
                        alt={`${data[tabIndex].label} image`}
                        className="object-cover w-full h-full rounded-lg"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                      />
                    </div>

                    <motion.div
                      // className="bg-white shadow-lg p-8 rounded-lg w-full md:w-[calc(100%--3rem)] absolute top-[7rem] left-[12rem] md:h-auto overflow-auto"
                      className="custom-card"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    >
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </div>
          </Tabs>
        </div>



        <div
          ref={tabRef}
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick(index)}// Update tabIndex on click
                  className={`${tabIndex === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div ref={scrollRef} className="tab-content md:w-1/2 p-4" id="slide-sec" style={{ scrollMarginTop: "8.5rem" }}>
              {data[tabIndex] && (
                <div className="relative w-full">
                  {/* Image and Description Section */}
                  <div className="flex flex-col md:flex-col items-center md:items-start">
                    {/* Image Section */}
                    <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                      <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                        <img
                          src={data[tabIndex].image}
                          alt={`${data[tabIndex].label} image`}
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                    </div>

                    {/* Description Section */}
                    <div className="bg-white shadow-lg p-4 rounded-lg w-full md:w-[calc(100%-1rem)] md:ml-0 mt-4 md:mt-0">
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Tabs>
        </div>

        {/* /Our Success Stories section */}
        <div className="flex flex-col items-center py-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                Our Success Stories{" "}
              </p>
            </div>
          </h1>
        </div>

        <div
          className="flex justify-center items-center lg:mt-0 py-2 bg-gray-100 mb-4"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="w-3/4 sm:w-3/4 md:w-1/2 max-w-4xl">
            <Slider {...sliderSettings}>
              {companyInfo.map((company, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center   pt-4 text-center h-[250px] sm:h-[200px] md:h-[250px] transform transition-all duration-300 hover:scale-105 hover:shadow-2xl my-6"
                  style={{
                    borderRadius: '1rem', // Fixes the rounded corners for all states
                  }}
                >
                  {/* Logo Container */}
                  <div className="flex justify-center items-center w-full h-3/5 ">
                    <img
                      src={company.logo}
                      alt={company.name}
                      className="icon-responsive mb-4 max-h-full object-contain drop-shadow-md"
                    />
                  </div>
                  {/* Company Name */}
                  <div className="flex justify-center items-center w-full">
                    <h3 className="cs-lable text-xl font-bold mb-2 text-white drop-shadow-lg">
                      {company.name}
                    </h3>
                  </div>
                  {/* Technology */}
                  <p className="text-lg text-grey-900 font-medium">
                    {company.technology}
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>



        <CorporateTestimonials />
        <Clientele />

        <StickyElements />
        <Footer />
      </div>
    </>
  );
};

const companyInfo = [
  {
    count: "01",
    logo: "/assets/images/Zensar.png",
    name: "Zensar Technologies",
    technology: "Java Spring Boot, Angular Full Stack Development",
    description: "",

  },
  {
    count: "02",
    logo: "/assets/images/Tata Technologies.jpg",
    name: "Tata Technologies",
    technology: "Tableau & SQL",
    description: "",

  },
  {
    count: "03",
    logo: "/assets/client_logos/cl (14).jpg",
    name: "JISA Softech",
    technology: "Core JAVA, JSP, Spring Boot, Servlet",
    description: "",

  },
  {
    count: "04",
    logo: "/assets/client_logos/cl (40).jpg",
    name: "Thomas Cook",
    technology: "EJB, Knockout Js, My SQL Database",
    description: "",

  },
  {
    count: "05",
    logo: "/assets/client_logos/cl (3).jpg",
    name: "Prorigo Software Pvt. Ltd.",
    technology: "Dot Net, C#",
    description: "",

  },
  {
    count: "06",
    logo: "/assets/client_logos/cl (11).jpg",
    name: "Capita India",
    technology: "QA with Automation",
    description: "",

  },
  {
    count: "07",
    logo: "/assets/images/spadeWorx-logo.png",
    name: "SpadeWorx Software Services",
    technology: "Dot Net, SharePoint",
    description: "",

  },
  {
    count: "08",
    logo: "/assets/client_logos/cl (20).jpg",
    name: "T-Systems (Xtenteam)",
    technology: "C#, ASP Dot Net, MVC",
    description: "",

  },
  {
    count: "09",
    logo: "/assets/client_logos/cl (37).jpg",
    name: "Kirloskar Pneumatic",
    technology: "Oracle Apex",
    description: "",

  },
  {
    count: "10",
    logo: "/assets/client_logos/cl (23).jpg",
    name: "Benchmark IT Solutions",
    technology: "Dot Net, C#, ASP .NET, MVC",
    description: "",

  },
  {
    count: "11",
    logo: "/assets/client_logos/cl (10).jpg",
    name: "Wishtree Technologies",
    technology: "Mean, Mern Stack, Mobile Development: Kotline, SwiftFlutter, DevOps",
    description: "",

  },
  {
    count: "12",
    logo: "/assets/client_logos/cl (1).jpg",
    name: "Devtron Inc",
    technology: "React Js, Golang, DevOps",
    description: "",

  },
  {
    count: "13",
    logo: "/assets/client_logos/cl (22).jpg",
    name: "Shaurya Infotech",
    technology: "Angular, Dot Net, Mobile Development: Flutter,",
    description: "",

  },
  {
    count: "14",
    logo: "/assets/images/Proxima.png",
    name: "Proxima Group",
    technology: "Managing Non-Work Screen, Health & Wellness",
    description: "",

  },
  {
    count: "15",
    logo: "/assets/client_logos/cl (37).jpg",
    name: "Kirloskar Pneumatic",
    technology: "Python with SQL",
    description: "",

  }
  // ... more company information objects
];

export default CorporateTraining;


