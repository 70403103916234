import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Create the context
export const CountryContext = createContext("India");

// Create the provider component
export const CountryProvider = ({ children }) => {
    const [country, setCountry] = useState();
    const [loading, setLoading] = useState(true);  // Loading state for API request
    const [error, setError] = useState(null);      // Error state for failed API requests
  
    useEffect(() => {
      // Fetch country from the API
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
            console.log(response)
          setCountry(response.data.country_name); // Extract the country name
          setLoading(false);  // Stop loading once data is fetched
        })
        .catch((error) => {
          console.error("Error fetching country:", error);
          setLoading(false);
          setCountry("India");      // Stop loading
          setError("Unable to fetch country information.");
        });
    }, []);

  return (
    <CountryContext.Provider value={country}>
      {children}
    </CountryContext.Provider>
  );
};
