import React, { useEffect, useRef } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
// import {
//   Tabs,
//   TabsHeader,
//   TabsBody,
//   Tab,
//   TabPanel,
// } from "@material-tailwind/react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { motion } from "framer-motion";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";

const Outsourcing = () => {
  useEffect(() => {
    document.title = "Outsourcing - Think Quotient";
    AOS.init();
  }, []);

  const [activeTab, setActiveTab] = React.useState("tab1");
  const [tabIndex, setTabIndex] = React.useState(0)
  const data = [
    {
      count: "01",
      label: "Enhance Operational Efficiency",
      value: "tab1",
      image: "/assets/images/eoe.jpeg",
      desc: `We specialize in understanding your unique business processes and needs. Our outsourcing services are designed to streamline your operations, reduce bottlenecks, and improve overall efficiency. `,
    },
    {
      count: "02",
      label: "Seamless Transition",
      value: "tab2",
      image: "/assets/images/st.jpg",
      desc: `Whether you're a non-profit organization or a large-scale enterprise, we ensure a seamless transition to our outsourcing solutions. Your operations and revenue will continue unaffected as we handle the process with precision.`,
    },
    {
      count: "03",
      label: "Boost Cost-Efficiency",
      image: "/assets/images/bce.jpg",
      value: "tab3",
      desc: `Outsourcing with us isn't just about optimizing processes; it's also a strategic move to boost your revenue. By reducing operational costs and enhancing efficiency, we contribute to your bottom line.`,
    },
    {
      count: "04",
      label: "User-Friendly Solutions",
      image: "/assets/images/ufs.jpg",
      value: "tab4",
      desc: `Our outsourcing solutions are user-centric. Regardless of your industry, we provide user-friendly tools and systems that make it easy for your team to adapt and excel.`,
    },
    {
      count: "05",
      label: "Digitize Operations",
      image: "/assets/images/do.jpg",
      value: "tab5",
      desc: `Embrace the digital era with our outsourcing services. We leave no stone unturned in digitizing your complex business processes, saving you valuable time and effort.`,
    },
    {
      count: "06",
      label: "Data-Driven Decisions",
      image: "/assets/images/ddc.jpg",
      value: "tab6",
      desc: `Access better business insights through our data analytics and reporting. We empower you to make informed, data-backed decisions that drive growth and success.`,
    },
    {
      count: "07",
      label: "Industry Expertise",
      image: "/assets/images/ie.jpg",
      value: "tab7",
      desc: `Stay ahead of the competition with our industry-specific knowledge and technology. We keep you informed about the latest trends and innovations in your field.`,
    },
    {
      count: "08",
      label: "Competitive Edge",
      image: "/assets/images/ce.jpg",
      value: "tab8",
      desc: `Our organized approach, improved decision-making, and enhanced customer experience give you a significant competitive advantage. Outsource with us to lead your industry with confidence.`,
    },
  ];
  const fadeVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };

  const scrollRef = useRef(null); // Reference to the content container

  const handleTabClick = (index) => {
    setTabIndex(index);
    // Scroll to the container
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();
  return (

    <>
      <Helmet>
        {/* Page Title */}
        {/* <title>{pageTitle}</title> */}
        <meta name="title" content={metaTitle} />

        {/* Remaining Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Facebook Pixel Code */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '473030589157148');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>{`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=473030589157148&ev=PageView&noscript=1"/>
        `}</noscript>

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []; 
          function gtag() { 
            dataLayer.push(arguments); 
          } 
          gtag("js", new Date()); 
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>

      </Helmet>

      <div className="services-2">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="outsourcing-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-4xl font-semibold mb-4 ">
                  Your gateway to streamlined efficiency and unparalleled
                  expertise in outsourcing.
                </h1>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="container mx-auto md:py-20 py-10 px-2">
            <div
              className="text-black text-2xl md:text-5xl font-bold text-center md:w-1/2 mx-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Let ThinkQuotient Help You Create Your
              <span className="about-us-banner-txt"> Online Presence</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center py-14 my-10  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <b>
              Whether you're a startup or an established enterprise, we're here to
              optimize your operations, reduce costs, and unleash your true
              potential.
            </b>
            <br /> Our dedicated team of experts is committed to delivering
            innovative solutions that align seamlessly with your business
            objectives. Let's embark on this journey together, where efficiency
            meets excellence, and success knows no bounds. Welcome to
            ThinkQuotient Outsourcing - Your Partner for Growth.
          </h1>
        </div>

        <div className="container  mx-auto py-10 px-4 hidden  lg:block">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex(index)} // Update tabIndex on click
                  className={`${tabIndex === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div className="tab-content md:w-1/2 p-4" id="slide-sec">
              {data[tabIndex] && (
                <motion.div
                  key={tabIndex} // Re-run animation on tabIndex change
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeVariants}
                  className="relative w-full"
                >
                  <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                    <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                      <motion.img
                        src={data[tabIndex].image}
                        alt={`${data[tabIndex].label} image`}
                        className="object-cover w-full h-full rounded-lg"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                      />
                    </div>

                    <motion.div
                      // className="bg-white shadow-lg p-8 rounded-lg w-full md:w-[calc(100%--3rem)] absolute top-[7rem] left-[12rem] md:h-auto overflow-auto"
                      className="custom-card"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    >
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </div>
          </Tabs>
        </div>

        <div className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick(index)}   // Update tabIndex on click
                  className={`${tabIndex === index
                    ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                    : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div ref={scrollRef} className="tab-content md:w-1/2 p-4" id="slide-sec" style={{ scrollMarginTop: "8.5rem" }} >
              {data[tabIndex] && (
                <div className="relative w-full">
                  {/* Image and Description Section */}
                  <div className="flex flex-col md:flex-col items-center md:items-start">
                    {/* Image Section */}
                    <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                      <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                        <img
                          src={data[tabIndex].image}
                          alt={`${data[tabIndex].label} image`}
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                    </div>

                    {/* Description Section */}
                    <div className="bg-white shadow-lg p-4 rounded-lg w-full md:w-[calc(100%-1rem)] md:ml-0 mt-4 md:mt-0">
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Tabs>
        </div>


        {/* Floating Divs */}
        <div

        >
          <div className="mt-12 text-center">
            <h1 className="text-xl md:text-2xl font-bold md:w-2/3 mx-auto">
              A Customer-Centric Approach To Bringing Innovations To Your Business
            </h1>
          </div>
          <div className="mt-12 md:flex flex-wrap items-center justify-center space-y-8 md:space-y-0 md:space-x-2">
            {/* First style  */}
            <div className="w-full md:w-[24%]">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-1.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
                <h3 className="font-bold w-4/6">
                  You Reach Out to ThinkQuotient for Your Outsourcing Needs
                </h3>
                <p className="mt-4 text-[14px] font-normal leading-relaxed w-5/6">
                  It all begins with you making the initial connection. Contact
                  ThinkQuotient with your specific outsourcing requirements, and
                  your journey towards efficient solutions starts right here.
                </p>
              </div>
            </div>

            {/* Second style  */}
            <div className="w-full md:w-[24%]">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-2.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
                <h3 className="font-bold  w-8/12">
                  We Engage in Meaningful Conversations to Understand Your
                  Business
                </h3>
                <p className="mt-4 text-sm leading-relaxed font-normal w-5/6">
                  ThinkQuotient's dedicated team takes the time to engage with you
                  in meaningful conversations. We aim to gain a profound
                  understanding of your business, its unique aspects, industry
                  challenges, and aspirations.{" "}
                </p>
              </div>
            </div>

            {/* Third style  */}
            <div className="w-full md:w-[24%]">
              <div className="justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-3.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
                <h3 className="font-bold w-10/12">
                  We Analyze Your Requirements and Craft a Tailored Strategy
                </h3>
                <p className="mt-4 text-sm font-normal leading-relaxed w-4/5">
                  Drawing upon our expertise and market insights, we diligently
                  analyze your outsourcing requirements. From there, we create a
                  customized strategic plan that aligns perfectly with your
                  business objectives.
                </p>
              </div>
            </div>

            {/* Fourth style  */}
            <div className="w-full md:w-[24%]">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-4.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
                <h3 className="font-bold  w-8/12">
                  We Collaborate with You to Define the Right Plan
                </h3>
                <p className="mt-4 text-sm font-normal w-5/6 leading-relaxed">
                  Collaboration is at the heart of our approach. We work closely
                  with you to ensure that the strategic plan not only meets your
                  vision but also aligns with your short-term and long-term
                  objectives.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*Second Floating Divs */}
        <div className="container mx-auto pt-2 pb-20">
          <div className=" md:flex items-center md:space-x-8 justify-center">
            {/* First style  */}
            <div className="w-full md:w-[24%]">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-1.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
                <h3 className="font-bold w-4/6">
                  Our Team Springs into Action to Execute the Plan
                </h3>
                <p className="mt-4 text-[14px] font-normal leading-relaxed w-5/6">
                  Once the plan is finalized, ThinkQuotient's team of skilled
                  professionals initiates the execution phase. Leveraging our
                  expertise in outsourcing and technology, we get to work on your
                  deliverables.
                </p>
              </div>
            </div>

            {/* Second style  */}
            <div className="w-full md:w-[24%] mt-6 md:mt-0">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-2.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
                <h3 className="font-bold  w-8/12">
                  Quality Assurance is a Top Priority
                </h3>
                <p className="mt-4 text-sm leading-relaxed font-normal w-5/6">
                  Quality is ingrained in everything we do. Throughout the
                  process, we rigorously test and assess our solutions to ensure
                  they meet the highest standards and effectively address your
                  outsourcing needs.{" "}
                </p>
              </div>
            </div>

            {/* Third style  */}
            <div className="w-full md:w-[24%] mt-6 md:mt-0">
              <div className="justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-3.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
                <h3 className="font-bold w-10/12">
                  We Deliver the Outsourced Services Exactly as You Need Them
                </h3>
                <p className="mt-4 text-sm font-normal leading-relaxed w-4/5">
                  Our ultimate goal is your satisfaction. We deliver the
                  outsourced services precisely as you envisioned, ensuring that
                  they seamlessly integrate with your business operations and
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>

        <TestimonialsandBlogSec />
        <div>

          <br />
        </div>
        <ClientLogoCarousel />
        <Footer />
      </div>
    </>
  );
};

export default Outsourcing;
